<template>
  <div class="zn-search" :class="{ 'zn-loading': loading }">
    <div class="zn-search-container">
      <input
        ref="input"
        type="text"
        :placeholder="placeholder"
        :value="query"
        :disabled="loading"
        :class="{ 'zn-disabled': loading }"
        @input="$emit('input', $event.target.value)"
        @focus="$emit('focus', $event)"
        @blur="$emit('blur', $event)"
      />
      <Icon v-if="!allowClear || query == ''" name="search" />
      <Icon v-else class="zn-search__clear" name="clear" @click.native="$emit('input', '');$emit('clear');$refs.input.focus();" />
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'query',
    event: 'input'
  },
  props: {
    query: String,
    placeholder: {
      type: String,
      default: 'Search...'
    },
    allowClear: Boolean,
    loading: Boolean
  }
}
</script>

<style lang="scss" scoped>
.zn-search {
  .zn-search-container {
    position: relative;

    .material-icons-outlined {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 5px;
      font-size: 20px;
      color: $fade;
      display: flex;
      align-items: center;

      &.zn-search__clear {
        cursor: pointer;
        color: $input;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    input {
      width: 100%;
      padding: 12px 32px 12px 12px;
      font-size: 14px;
      border: 1px solid $light;
      border-radius: 5px;
      outline: none;
      transition: border-color 0.5s;

      &.zn-disabled {
        cursor: not-allowed;
      }

      &:focus {
        border-color: $primary;
      }

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $fade;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $fade;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: $fade;
      }
    }
  }
}
</style>
