import * as snippet from '@/utils/snippet.js'

export default function(roles) {
  return function({ next, router, store }) {
    if (snippet.intersectionArray(store.state.auth.userInfo.roles, roles).length == 0) {
      store.dispatch('auth/logout')
      return router.push('/login')
    }
  
    return next()
  }
}
