<template>
  <button class="zn-button zn-border-none" :class="classList" @click="$emit('click', $event)" :disabled="disabled">
    <span>
      <LoadingSvg v-if="loading" />
      <slot></slot>
    </span>
  </button>
</template>

<script>
import LoadingSvg from '@/assets/loading.svg'

export default {
  components: {
    LoadingSvg
  },
  props: {
    type: {
      type: String,
      default: 'raw',
      validator: function(value) {
        return ['raw', 'primary', 'secondary', 'light', 'danger'].indexOf(value) >= 0
      }
    },
    disabled: Boolean,
    loading: Boolean
  },
  computed: {
    classList() {

      const stateStyle = {
        'zn-button-disabled': this.disabled
      }

      switch (this.type) {
        case 'raw':
          return {...stateStyle}
        case 'primary':
          return {
            ...stateStyle,
            'zn-text-white': true,
            'zn-bg-primary': true
          }
        case 'secondary':
          return {
            ...stateStyle,
            'zn-text-white': true,
            'zn-bg-secondary': true
          }
        case 'danger':
          return {
            ...stateStyle,
            'zn-text-white': true,
            'zn-bg-danger': true
          }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.zn-button {
  text-align: center;
  display: inline-block;
  font-size: 15px;
  padding: 0 14px;
  border-radius: 5px;
  cursor: pointer;
  height: 32px;

  &:hover {
    opacity: 0.8;
  }

  &.zn-button-disabled {
    background-color: $light;
    color: white;
    cursor: not-allowed;
  }

  & > span {
    display: flex;

    & > svg {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
