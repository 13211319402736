import request from '@/utils/request.js'
import API_URL from '@/utils/sourceApiUrl'
import CLIENT_URL from '@/utils/sourceClientUrl'

const FAKE_DATA = [
  {
    id: 1,
    plan: 'trail',
    storeHash: 'loading'
  },
  {
    id: 2,
    plan: 'trail',
    storeHash: 'loading'
  },
  {
    id: 3,
    plan: 'trail',
    storeHash: 'loading'
  }
]

const state = {
  listShops: {
    shopify: FAKE_DATA,
    bigcommerce: FAKE_DATA
  },
  filterId: 'asc'
}

const getters = {
  fetchedListShops(state, getters, rootState) {
    return state.listShops[rootState.platform] != FAKE_DATA
  },
  clientHost(state, getters, rootState) {
    return CLIENT_URL[rootState.platform]
  }
}

const mutations = {
  setListShops(state, { platform, listShops }) {
    state.listShops[platform] = listShops
  },
  setFilterId(state, filterId) {
    state.filterId = filterId
  }
}

const actions = {
  async fetchListShop({ state, rootState, commit }) {
    const platform = rootState.platform
    const response = await request.get(`${API_URL[platform]}/admin/shops`)
    commit('setListShops', { platform, listShops: response.shops })
    return response.shops
  },
  async getShopToken({ state, rootState, commit }, shop) {
    const platform = rootState.platform
    const response = await request.get(`${API_URL[platform]}/admin/shops/token`, { storeHash: shop.storeHash, shopName: shop.name })
    return response.token
  },
  async extendTrial({ state, rootState, commit }, payload) {
    const platform = rootState.platform
    const response = await request.put(`${API_URL[platform]}/admin/shops/extend-trial`, payload)
    return response
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
