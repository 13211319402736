<template>
  <transition name="fade" tag="div" class="zn-snackbar-wrapper">
    <div class="zn-snackbar" v-if="$store.state.notify">
     <div class="zn-snackbar-message">{{$store.state.notify}}</div>
     <div class="zn-snackbar-close-btn" @click="$store.commit('notify', null)"><Icon name="close" /></div>
    </div>
  </transition>
</template>

<script>
export default {
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.zn-snackbar {
  position: fixed;
  width: 480px;
  padding: 12px 16px;
  top: 32px;
  right: calc((100% - 300px - 540px) / 2);
  background-color: black;
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 2px 8px 0px $fade;
  z-index: 99999;

  &.fade-enter-active {
    animation: fade 0.15s;
  }

  &.fade-leave-active {
    animation: fade 0.15s reverse;
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .zn-snackbar-close-btn {
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>