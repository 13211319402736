<template>
  <div class="zn-shop-asset-item">
    <h2 class="zn-shop-asset-item-heading zn-noselect" @click="expand = !expand; $emit('toggle')">
      <Icon :name="expand ? 'topic' : 'folder'" />
      <span class="zn-shop-asset-item-heading-value">{{ name }}</span>
    </h2>

    <ul v-if="expand" class="zn-shop-asset-list">
      <li v-if="singularName != 'config'" class="zn-shop-asset-create-item" @click="triggerCreateAsset">
        <Icon name="add" />
        <span>Create new {{ singularName }}</span>
      </li>
      <li v-for="asset in assets" :key="asset" @click="$emit('openAsset', `${name}/${asset}`)" v-tooltip="asset">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <path d="M4.8 14.4c0 .426-.168.832-.47 1.13l-.468.47.47.47c.3.298.468.705.468 1.13V24c0 .883.717 1.6 1.6 1.6.885 0 1.6.715 1.6 1.6s-.715 1.6-1.6 1.6c-2.646 0-4.8-2.154-4.8-4.8v-5.738l-1.13-1.13c-.627-.627-.627-1.638 0-2.263l1.13-1.134V8c0-2.646 2.154-4.8 4.8-4.8.885 0 1.6.715 1.6 1.6s-.715 1.6-1.6 1.6c-.883 0-1.6.717-1.6 1.6v6.4zm26.73.47c.627.625.627 1.636 0 2.26l-1.13 1.132V24c0 2.646-2.154 4.8-4.8 4.8-.885 0-1.6-.715-1.6-1.6s.715-1.6 1.6-1.6c.883 0 1.6-.717 1.6-1.6v-6.4c0-.426.168-.832.47-1.13l.468-.47-.47-.47c-.3-.298-.468-.705-.468-1.13V8c0-.883-.717-1.6-1.6-1.6-.885 0-1.6-.715-1.6-1.6s.715-1.6 1.6-1.6c2.646 0 4.8 2.154 4.8 4.8v5.738l1.13 1.13zM19.795 6.513c.82.328 1.22 1.26.893 2.08l-6.4 16c-.25.626-.853 1.006-1.486 1.006-.197 0-.4-.04-.593-.117-.82-.328-1.22-1.26-.895-2.078l6.4-16c.328-.824 1.264-1.222 2.08-.893z"></path>
        </svg>
        <span>{{ asset }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    assets: Array,
    name: String
  },
  data() {
    return {
      expand: false
    }
  },
  computed: {
    singularName() {
      return this.name.replace(/s$/, '')
    }
  },
  methods: {
    forceToggle(expand) {
      // Force toggle from parent
      this.expand = expand
    },
    getExtensionByName(fileName) {
      const parts = fileName.split('.')
      return parts[parts.length - 1]
    },
    triggerCreateAsset() {
      this.$store.commit('prompt', {
        title: `Create new ${this.singularName}`,
        confirmText: 'Create',
        confirmHandler: (newFileName) => {
          this.$store.dispatch('shopAssets/createShopAsset', { fileName: `${this.name}/${newFileName}`, fileData: '' })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.zn-shop-asset-item {
  overflow-x: hidden;
  overflow-y: hidden;
  .zn-shop-asset-item-heading {
    display: flex;
    align-items: flex-end;
    padding: 8px 0;
    cursor: pointer;

    .zn-shop-asset-item-heading-value {
      margin-left: 8px;
      text-transform: capitalize;
      font-weight: bold;
    }
  }

  .zn-shop-asset-list {
    display: block;
    list-style: none inside none;

    li {
      padding: 4px 0 4px 32px;
      display: flex;
      cursor: pointer;

      &:hover {
        color: $primary;
      }

      svg {
        flex: 0 0 20px;
        margin-right: 4px;
        width: 20px;
        height: 20px;
        color: transparent;
        fill: rgb(92, 95, 98);
      }

      span {
        white-space: nowrap;
      }
    }

    .zn-shop-asset-create-item {
      color: $primary;
      align-items: center;
      padding: 4px 0 4px 28px;
    }
  }
}
</style>
