<template>
  <div class="zn-shop">
    <div class="zn-shop-left">
      <div class="zn-shop-name" :class="{ 'zn-loading': loading }">Shop {{ shop.id }}</div>
      <div class="zn-shop-email" :class="{ 'zn-loading': loading }">{{ shop.email }}</div>
      <div class="zn-shop-path" :class="{ 'zn-loading': loading }">{{ livePageUrl }}</div>
    </div>
    <div class="zn-shop-right">
      <div class="zn-shop-plan" :class="{ 'zn-loading': loading }" :data-plan="shop.plan">{{ shop.plan }}</div>
      <Context ref="context">
        <div slot="handle" class="zn-shop-more" :class="{ 'zn-loading': loading }">
          <Icon name="more_horiz" />
        </div>

        <ul slot="context" class="zn-shop-context">
          <li @click="viewLive">View shop</li>
          <li @click="login">Login as staff</li>
          <li @click="handleExtendTrial" v-if="shop.plan == 'trial'">Extend trial</li>
          <li @click="handleEditCodeOfShop" v-if="platform == 'shopify'">Edit theme</li>
        </ul>
      </Context>
    </div>
  </div>
</template>

<script>
import Context from '@/components/common/Context.vue'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import jwtUtils from '@/utils/jwtUtils'

export default {
  components: {
    Context
  },
  props: {
    shop: Object,
    loading: Boolean
  },
  computed: {
    ...mapState({
      platform: state => state.platform
    }),
    ...mapGetters({
      clientHost: 'shops/clientHost'
    }),
    livePageUrl() {
      if (this.platform == 'bigcommerce') {
        return `https://store-${this.shop.storeHash}.mybigcommerce.com`
      } else {
        return `https://${this.shop.name}`
      }
    }
  },
  methods: {
    ...mapActions({
      getShopToken: 'shops/getShopToken',
      extendTrial: 'shops/extendTrial'
    }),
    ...mapMutations({
      selectShop: 'shopAssets/selectShop'
    }),
    viewLive() {
      this.$refs.context.closeContext()
      window.open(this.livePageUrl)
    },
    async login() {
      this.$refs.context.closeContext()
      const token = await this.getShopToken(this.shop)
      if (this.platform == 'bigcommerce') {
        window.open(`${this.clientHost}?token=${token}`)
      } else {
        const targetWindow = window.open(`${this.clientHost}?remote=true`)
        setTimeout(() => {
          const body = {
            adminToken: jwtUtils.get(),
            shopToken: token,
            shopName: this.shop.name
          }
          targetWindow.postMessage(body, this.clientHost)
        }, 500)
      }
    },
    handleExtendTrial() {
      this.$store.commit('prompt', {
        title: 'Extend trial by day',
        message: 'n days from today',
        type: 'number',
        confirmText: 'Extend',
        confirmHandler: async days => {
          try {
            days = +days
            if (isNaN(days) || days <= 0) return this.$store.commit('notify', 'Day invalid')
            let expiredDate = new Date(Date.now() + days * 24 * 60 * 60 * 1000)
            let effectiveYear = expiredDate.getFullYear()
            let effectiveMonth = expiredDate.getMonth() + 1
            let effectiveDay = expiredDate.getDate()
            let effectiveDate = `${effectiveYear}-${effectiveMonth < 10 ? '0' : ''}${effectiveMonth}-${effectiveDay < 10 ? '0' : ''}${effectiveDay}`

            await this.extendTrial({ shopId: this.shop.id, effectiveDate })
            this.$store.commit('notify', 'Extended trial duration')
          } catch (err) {
            console.log('Warn 1: ', err)
            this.$store.commit('notify', 'Something went wrong. Please try again.')
          }
        }
      })
    },
    handleEditCodeOfShop() {
      this.selectShop(this.shop.id)
      this.$router.push('/theme-editor')
    }
  }
}
</script>

<style lang="scss" scoped>
.zn-shop {
  margin-top: 12px;
  background-color: white;
  border: 1px solid $light;
  border-radius: 5px;
  cursor: pointer;
  transition: box-shadow 0.5s;
  display: flex;
  align-items: center;
  &:hover {
    box-shadow: 0px 0px 15px rgba(black, 0.1);
  }

  .zn-shop-left {
    flex-basis: 420px;
    padding: 16px 16px 16px 32px;
    color: black;

    .zn-shop-name {
      height: 20px;
      align-items: center;
      display: flex;

      ::v-deep .material-icons-outlined {
        position: relative;
        top: -2px;
        color: $fade;
        margin-left: 8px;
        font-size: 26px;

        &.lock {
          font-size: 22px;
          color: $warning;
        }
      }
    }

    .zn-shop-path,
    .zn-shop-email {
      color: $fade;
      font-size: 14px;
      margin-top: 8px;
    }
  }

  .zn-shop-right {
    flex-grow: 1;
    display: flex;
    padding: 16px 32px 16px 0;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    .zn-shop-plan {
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 300;
      color: white;
      padding: 4px 8px;
      border-radius: 5px;
      margin-right: 16px;

      &[data-plan='free'],
      &[data-plan='trial'] {
        background-color: $fade;
      }

      &[data-plan='starter'] {
        background-color: rgba($primary, 0.7);
      }

      &[data-plan='pro'],
      &[data-plan='annual'] {
        background-color: rgba($secondary, 0.7);
      }
    }

    .zn-shop-more {
      width: 32px;
      height: 32px;
      text-align: center;
      border-radius: 50%;
      transition: background-color 0.5s;
      &:hover {
        background-color: rgba($light, 0.7);
      }
      ::v-deep .material-icons-outlined {
        position: relative;
        top: 2px;
        font-size: 28px;
      }
    }

    ul.zn-shop-context {
      background-color: white;
      box-shadow: 0px 0px 15px rgba(black, 0.2);
      position: absolute;
      text-align: left;
      right: 24px;
      width: 165px;
      padding: 8px 0;
      border-radius: 5px;
      z-index: 9999;

      li {
        padding: 10px 16px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.danger {
          color: $danger;
        }
        &:hover {
          background-color: rgba($light, 0.5);
        }

        ::v-deep .material-icons-outlined {
          position: relative;
          top: -2px;
          color: $fade;
          margin-left: 8px;
          font-size: 26px;

          &.lock {
            font-size: 22px;
            color: $warning;
          }
        }
      }
    }
  }
}
</style>
