import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vClickOutside from 'v-click-outside'
import vTooltip from 'v-tooltip'

import GoogleAuth from '@/plugins/google_oAuth'
import sfcMiddlewareExtension from '@/middleware/sfc-middleware-extention'

import Icon from '@/components/common/Icon.vue'
import EmptyState from "@/components/common/EmptyState.vue"
import Search from '@/components/common/Search.vue'
import Button from '@/components/common/Button.vue'
import TextInput from '@/components/common/TextInput.vue'

import '@/utils/scss/spacer.scss'
import '@/utils/scss/text.scss'
import '@/utils/scss/decoration.scss'

Vue.config.productionTip = false

Vue.component('Icon', Icon)
Vue.component('EmptyState', EmptyState)
Vue.component('Search', Search)
Vue.component('Button', Button)
Vue.component('TextInput', TextInput)
Vue.use(vClickOutside)
Vue.use(vTooltip, {
  defaultContainer: false,
  defaultDelay: {
    show: 800,
    hide: 100
  }
})
Vue.use(GoogleAuth, {
  clientId: process.env.VUE_APP_GOOGLE_APP_CLIENT_ID,
  scope: 'profile email',
  prompt: 'select_account'
})

router.beforeEach(sfcMiddlewareExtension(router, store))

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
