<template>
  <div class="zn-container">
    <router-view></router-view>
    <Snackbar />
  </div>
</template>

<script>
import Snackbar from '@/components/common/Snackbar'

export default {
  name: 'LoginLayout',
  components: {
    Snackbar
  }
}
</script>

<style>
#app, .zn-container {
  width: 100vw;
  height: 100vh;
}
</style>
