<template>
  <i class="material-icons-outlined">
    {{name}}
  </i>
</template>

<script>
export default {
  props: {
    name: String
  }
}
</script>

<style lang="scss" scoped>
.material-icons-outlined {
  -webkit-text-stroke: 0.3px white;
}
</style>