import Vue from 'vue'
import VueRouter from 'vue-router'

import LoginLayout from '@/layouts/login.vue'
import DefaultLayout from '@/layouts/default.vue'

import Dashboard from '@/views/Dashboard'
import ShopManager from '@/views/ShopManager'
import ThemeEditor from '@/views/ThemeEditor'
import Login from '@/views/Login.vue'
import TemplateManager from '@/views/TemplateManager.vue'
import SectionManager from '@/views/SectionManager.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: LoginLayout,
    children: [
      {
        path: '',
        name: 'Login',
        component: Login
      }
    ]
  },
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'HomePage',
        component: Dashboard
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: '/shop-manager',
        name: 'Shop manager',
        component: ShopManager
      },
      {
        path: '/theme-editor',
        name: 'Theme editor',
        component: ThemeEditor
      },
      {
        path: '/template-manager',
        name: 'Template manager',
        component: TemplateManager
      },
      {
        path: '/section-manager',
        name: 'Section manager',
        component: SectionManager
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
