<template>
  <div class="zn-input-text">
    <input
      type="text"
      autocomplete="off"
      :value="value"
      :maxlength="max"
      :placeholder="placeholder"
      :disabled="disable"
      @input="$emit('input', $event.target.value)"
      @change="$emit('change', $event.target.value)"
    >
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: String,
    placeholder: {
      type: String,
      default: 'Enter here...'
    },
    disable: Boolean,
    max: Number
  }
}
</script>

<style lang="scss" scoped>
.zn-input-text {
  input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    outline: none;
    border: 1px solid $light;
    border-radius: 4px;

    &:disabled {
      cursor: no-drop;
    }

    &:focus {
      border-color: $primary !important;
    }
  }
}
</style>