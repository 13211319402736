<template>
  <main class="zn-main" :class="{ 'zn-processing': loading }" >
    <div class="zn-topbar">
      <div class="zn-topbar-left"></div>

      <div class="zn-topbar-right" v-if="openingTemplate">
        <template v-if="!isCreate">
          <Button v-if="openingTemplate.isHidden" type="primary" @click="handleRevertTemplate" :disabled="isDeleting" :loading="isDeleting" class="mr-2">{{ isDeleting ? 'Reverting' : 'Revert' }}</Button>
          <Button v-else type="danger" @click="handleDeleteTemplate" :disabled="isDeleting" :loading="isDeleting" class="mr-2">{{ isDeleting ? 'Deleting' : 'Delete' }}</Button>
        </template>
        <Button type="primary" :disabled="!openingTemplate.changed.length || isSaving" :loading="isSaving" @click="handleSaveTemplate">{{ isSaving ? 'Saving' : 'Save' }}</Button>
      </div>
      <div class="zn-topbar-right" v-if="reOrder">
        <Button type="primary" :disabled="(openingTemplate && !openingTemplate.changed.length) || isSaving" :loading="isSaving" @click="handleSaveTemplate">{{ isSaving ? 'Saving' : 'Save' }}</Button>
      </div>
    </div>

    <div class="zn-page-container">
      <div class="zn-page-sidebar">
        <Search v-model="keySearch" allowClear placeholder="Search template..." />
        <div class="zn-template-container">
          <div class="zn-template-item zn-text-primary" :class="{ 'zn-loading': loading }" @click="handleCreateTemplate">
            <Icon name="add" />
            <span>Create new template</span>
          </div>
          <Draggable :list="templates" :disabled="openingTemplate || keySearch" v-bind="dragOptions" @change="reOrder=true" >
            <template v-for="template in templateFiltered">
              <div
                class="zn-template-item"
                :key="template.key"
                :class="{
                  'zn-loading': loading,
                  'zn-template-opening': openingTemplate && openingTemplate.recordId == template.recordId,
                  'zn-is-hidden': template.isHidden
                }"
                @click="!reOrder && handleOpenTemplate(template)"
              >
                {{ template.name }}
              </div>
            </template>
          </Draggable>
        </div>
      </div>
      <div class="zn-main-page" v-if="openingTemplate">
        <div class="zn-template-part zn-template-title">
          <div :class="{ changed: openingTemplate.changed.includes('name') }">Template name</div>
          <TextInput placeholder="Enter template name" :value="openingTemplate.name" @input="handleChangeTemplateName" style="width: 280px;" />
        </div>

        <div class="zn-template-part zn-template-type">
          <table>
            <tr>
              <td>Is blog template</td>
              <td><Switcher name="ahhi" :value="openingTemplate.isBlogTemplate" @input="handleSwitchType('isBlogTemplate', $event)" /></td>
            </tr>

            <tr>
              <td>Is regular template</td>
              <td><Switcher name="ahhi" :value="openingTemplate.isRegularTemplate" @input="handleSwitchType('isRegularTemplate', $event)" /></td>
            </tr>

            <tr>
              <td>In Shopify</td>
              <td><Switcher name="ahhi" :value="openingTemplate.inShopify" @input="handleSwitchType('inShopify', $event)" /></td>
            </tr>

            <tr>
              <td>In BigCommerce</td>
              <td><Switcher name="ahhi" :value="openingTemplate.inBigCommerce" @input="handleSwitchType('inBigCommerce', $event)" /></td>
            </tr>
          </table>
        </div>

        <div class="zn-template-part zn-template-screenshot">
          <div :class="{ changed: openingTemplate.changed.includes('thumbnail') }">
            <span>Template thumbnail</span>
            <label class="zn-upload-image">
              <Icon name="add" />
              <input type="file" accept="image/*" @change="handleUploadLocalImage" />
            </label>
          </div>
          <div class="zn-template-thumbnail" :style="{ backgroundImage: `url(${openingTemplate.thumbnailUrl})` }" @click="handleOpenThumbnail"></div>
        </div>

        <div class="zn-template-part zn-template-data">
          <div :class="{ changed: openingTemplate.changed.includes('bc_elements') }">Bigcommerce data</div>
          <CodeEditor :fileName="`bc_elements.json`" :fileData="openingTemplate.bc_elements" @change="handleChangeTemplateContent($event, 'bc_elements')" :key="updateKey" />
        </div>

        <div class="zn-template-part zn-template-data">
          <div :class="{ changed: openingTemplate.changed.includes('sp_elements') }">Shopify data</div>
          <CodeEditor :fileName="`sp_elements.json`" :fileData="openingTemplate.sp_elements" @change="handleChangeTemplateContent($event, 'sp_elements')" :key="updateKey" />
        </div>

        <div class="zn-template-part zn-template-preview">
          <div :class="{ changed: openingTemplate.changed.includes('preview') }">Template preview</div>
          <CodeEditor :fileName="`previewContent.html`" :fileData="openingTemplate.previewContent" @change="handleChangeTemplateContent($event, 'preview')" :key="updateKey" />
        </div>
      </div>
      <div class="zn-main-page" v-else>
        <EmptyState v-if="!openingTemplate" title="Select template and edit now" />
      </div>
    </div>
  </main>
</template>

<script>
import auth from '@/middleware/auth'
import author from '@/middleware/author'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import CodeEditor from '@/components/MonacoEditor.vue'
import clonedeep from 'lodash.clonedeep'
import * as snippet from '@/utils/snippet'
import Draggable from 'vuedraggable'
import Switcher from '@/components/common/Switcher.vue'

export default {
  middleware: [auth, author(['admin', 'dev'])],
  components: {
    CodeEditor,
    Draggable,
    Switcher
  },
  data() {
    return {
      keySearch: '',
      loading: false,
      openingTemplate: null,
      isSaving: false,
      updateKey: 1,
      isCreate: false,
      isDeleting: false,
      dragOptions: {
        sort: true,
        animation: 150,
        chosenClass: "zn-dragable-chose",
        dragClass: "zn-dragable-drag"
      },
      reOrder: false
    }
  },
  computed: {
    ...mapState({
      templates: state => state.template.templates
    }),
    ...mapGetters({
      fetchedTemplate: 'template/fetchedTemplate'
    }),
    templateFiltered() {
      return this.templates.filter(item => item.name.toLowerCase().includes(this.keySearch.toLowerCase()))
    }
  },
  watch: {
    fetchedTemplate(val) {
      if (!val) {
        this.fetchData()
      }
    }
  },
  async created() {
    if (!this.fetchedTemplate) {
      this.fetchData()
    }
  },
  methods: {
    ...mapActions({
      fetchListTemplate: 'template/fetchListTemplate',
      getContentPreview: 'template/getContentPreview',
      getTemplateData: 'template/getTemplateData',
      uploadImage: 'template/uploadImage',
      updatePreviewContent: 'template/updatePreviewContent',
      updateTemplateValue: 'template/updateTemplateValue',
      reOrderListTemplate: 'template/reOrderListTemplate',
      setHiddenTemplate: 'template/setHiddenTemplate'
    }),
    async fetchData() {
      this.loading = true
      try {
        await this.fetchListTemplate()
      } catch (err) {
        console.log('Warn 7:', err)
        this.$store.commit('notify', 'Cannot get list template, see more in devtool')
      }
      this.loading = false
    },
    handleOpenTemplate(template) {
      const openTemplate = async () => {
        this.isCreate = false
        this.loading = true
        const appendTasks = [this.getContentPreview(template), this.getTemplateData(template)]
        await Promise.all(appendTasks)
        this.openingTemplate = clonedeep(template)
        if (!this.openingTemplate.thumbnailUrl.includes('blob:http')) {
          this.openingTemplate.thumbnailUrl += `?t=${Date.now()}`
        }
        this.openingTemplate.changed = []
        this.updateKey++
        this.loading = false
      }

      if (this.openingTemplate && this.openingTemplate.changed.length) {
        this.$store.commit('confirm', {
          title: 'Are you sure?',
          message: 'Confirm to leave without save content',
          confirmText: 'Leave',
          status: 'danger',
          confirmHandler: () => {
            openTemplate()
          }
        })
        return
      }

      openTemplate()
    },
    handleOpenThumbnail() {
      window.open(this.openingTemplate.thumbnailUrl)
    },
    triggerChangeType(type) {
      if (!this.openingTemplate.changed.includes(type)) this.openingTemplate.changed.push(type)
    },
    handleSwitchType(field, value) {
      this.openingTemplate[field] = value
      this.triggerChangeType('type')
    },
    handleChangeTemplateName(value) {
      this.openingTemplate.name = value
      this.openingTemplate.key = value.toLowerCase().replace(/[^a-z\d]+/gi, '-')
      this.triggerChangeType('name')
    },
    handleChangeTemplateContent({ fileName, fileData }, type) {
      const field = fileName.split('.')[0]
      this.openingTemplate[field] = fileData
      this.triggerChangeType(type)
    },
    handleCreateTemplate() {
      const createTemplate = async () => {
        this.isCreate = true
        this.openingTemplate = {
          key: '',
          name: '',
          thumbnailUrl: '',
          previewUrl: '',
          previewContent: '',
          bc_elements: '',
          sp_elements: '',
          cms_elements: ''
        }
        this.openingTemplate.changed = []
        this.updateKey++
      }

      if (this.openingTemplate && this.openingTemplate.changed.length) {
        this.$store.commit('confirm', {
          title: 'Are you sure?',
          message: 'Confirm to leave without save content',
          confirmText: 'Leave',
          status: 'danger',
          confirmHandler: () => {
            createTemplate()
          }
        })
        return
      }

      createTemplate()
    },
    async handleReorderTemplates() {
      if (!this.reOrder) return
      this.isSaving = true

      const tempList = this.templates.map((item, index) => ({id: item.recordId, order: index}))
      await this.reOrderListTemplate({ templates: tempList })
      await this.fetchData()

      this.reOrder = false
      this.isSaving = false
    },
    async handleSaveNewTemplate() {
      if (!this.openingTemplate.thumbnailUrl) return this.$store.commit('notify', 'Please upload thumbnail image')

      const { recordId, name, key, bc_elements, sp_elements, cms_elements, isRegularTemplate, isBlogTemplate, inShopify, inBigCommerce } = this.openingTemplate
      const templateValue = { name, key, order: this.templates.length, isRegularTemplate: !!isRegularTemplate, isBlogTemplate: !!isBlogTemplate, inShopify: !!inShopify, inBigCommerce: !!inBigCommerce }

      try {
        templateValue.bc_elements = JSON.parse(bc_elements)
        templateValue.sp_elements = JSON.parse(sp_elements)
        templateValue.cms_elements = JSON.parse(cms_elements)
      } catch (e) {
        console.log('Warn 8', e)
        return this.$store.commit('notify', 'JSON format incorrect')
      }
      this.isSaving = true

      const tasks = []
      tasks.push(this.uploadImage({ fileName: 'undefined', thumbnailBase64: this.openingTemplate.thumbnailBase64 }))
      tasks.push(this.updatePreviewContent({ previewUrl: 'undefined', previewContent: this.openingTemplate.previewContent }))
      const response = await Promise.all(tasks)
      templateValue.thumbnailUrl = response[0].thumbnailUrl
      templateValue.previewUrl = response[1].previewUrl
      await this.updateTemplateValue({ recordId, templateValue })

      this.openingTemplate = null
      this.isCreate = false
      this.isSaving = false
      this.fetchData()
    },
    async handleSaveTemplate() {
      if (this.reOrder) return this.handleReorderTemplates()
      const duplicateTemplateName = this.templates.find(item => item.recordId != this.openingTemplate.recordId && (item.name == this.openingTemplate.name || item.key == this.openingTemplate.key))
      if (duplicateTemplateName) return this.$store.commit('notify', 'Template name must unique')
      if (!this.openingTemplate.name || !this.openingTemplate.key) return this.$store.commit('notify', 'Template name cannot empty')
      if (this.isCreate) return this.handleSaveNewTemplate()

      const tasks = []
      const originTemplateValue = this.templates.find(item => item.recordId == this.openingTemplate.recordId)
      const { recordId, name, key, bc_elements, sp_elements, cms_elements, order, previewUrl, isRegularTemplate, isBlogTemplate, inShopify, inBigCommerce } = this.openingTemplate
      const templateValue = {
        name, key, order, previewUrl,
        thumbnailUrl: originTemplateValue.thumbnailUrl,
        isRegularTemplate: isRegularTemplate,
        isBlogTemplate: isBlogTemplate,
        inShopify,
        inBigCommerce
      }

      try {
        templateValue.bc_elements = JSON.parse(bc_elements)
        templateValue.sp_elements = JSON.parse(sp_elements)
        templateValue.cms_elements = JSON.parse(cms_elements)
      } catch (e) {
        console.log('Warn 8', e)
        return this.$store.commit('notify', 'JSON format incorrect')
      }
      this.isSaving = true

      if (this.openingTemplate.changed.includes('thumbnail')) {
        const uploadTask = this.uploadImage({ fileName: originTemplateValue.thumbnailUrl, thumbnailBase64: this.openingTemplate.thumbnailBase64 })
        tasks.push(uploadTask)
      }

      if (this.openingTemplate.changed.includes('preview')) {
        const updatePreviewTask = this.updatePreviewContent({ previewUrl: this.openingTemplate.previewUrl, previewContent: this.openingTemplate.previewContent })
        tasks.push(updatePreviewTask)
      }

      if (snippet.intersectionArray(this.openingTemplate.changed, ['name', 'bc_elements', 'sp_elements', 'cms_elements', 'type']).length) {
        const updateTemplateTask = this.updateTemplateValue({ recordId, templateValue })
        tasks.push(updateTemplateTask)
      }

      await Promise.all(tasks)
      originTemplateValue.name = this.openingTemplate.name
      originTemplateValue.key = this.openingTemplate.key
      originTemplateValue.bc_elements = this.openingTemplate.bc_elements
      originTemplateValue.sp_elements = this.openingTemplate.sp_elements
      originTemplateValue.cms_elements = this.openingTemplate.cms_elements
      originTemplateValue.previewContent = this.openingTemplate.previewContent
      this.isSaving = false
      this.openingTemplate.changed = []
    },
    handleUploadLocalImage(e) {
      let file = e.target.files[0]
      if (!file.type.match(/image.*/)) return

      let reader = new FileReader()
      reader.addEventListener('load', async () => {
        let base64Data = reader.result
        const blob = await fetch(base64Data).then(res => res.blob())
        const url = window.URL.createObjectURL(blob, { type: file.type })
        this.openingTemplate.thumbnailUrl = url
        this.openingTemplate.thumbnailBase64 = base64Data

        let type = 'thumbnail'
        if (!this.openingTemplate.changed.includes(type)) this.openingTemplate.changed.push(type)
      }, false)

      if (file) {
        reader.readAsDataURL(file)
      }
    },
    handleDeleteTemplate() {
      this.$store.commit('confirm', {
        title: `Are you sure delete template?`,
        message: `Template <b>${this.openingTemplate.name}</b> will be hide in Dashboard and Editor.`,
        status: 'danger',
        confirmText: 'Delete',
        confirmHandler: async () => {
          this.isDeleting = true
          try {
            await this.setHiddenTemplate({ id: this.openingTemplate.recordId, value: true })
            this.openingTemplate = null
            this.fetchData()
          } catch (err) {
            this.$store.commit('notify', 'Cannot delete template, please see more in devtool')
            console.log('Warn 5:',err)
          }
          this.isDeleting = false
        }
      })
    },
    handleRevertTemplate() {
      this.$store.commit('confirm', {
        title: `Are you sure revert template?`,
        message: `Template <b>${this.openingTemplate.name}</b> will be show in Dashboard and Editor.`,
        confirmText: 'Revert',
        confirmHandler: async () => {
          this.isDeleting = true
          try {
            await this.setHiddenTemplate({ id: this.openingTemplate.recordId, value: false })
            this.openingTemplate = null
            this.fetchData()
          } catch (err) {
            this.$store.commit('notify', 'Cannot delete template, please see more in devtool')
            console.log('Warn 5:',err)
          }
          this.isDeleting = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.zn-dragable-chose {
  border-color: $secondary !important;
  opacity: 1;
}

.zn-dragable-drag {
  opacity: 0;
}

.zn-main {
  .zn-topbar {
    .zn-topbar-right {
      display: flex;

      .zn-button {
        height: 100%;
      }
    }
  }

  .zn-page-container {
    display: flex;
    height: calc(100% - 60px);
    padding: 8px;
    overflow: hidden;
    border-top: 1px solid $light;

    .zn-page-sidebar {
      flex: 0 0 280px;
      display: flex;
      flex-direction: column;
      padding: 8px;
      background: white;
      overflow: hidden;

      .zn-search {
        padding-right: 8px;
      }

      .zn-template-container {
        overflow-x: hidden;
        overflow-y: scroll;
        margin-right: -8px;

        .zn-template-item {
          padding: 8px 0;
          cursor: pointer;
          white-space: nowrap;
          border: 1px dashed transparent;

          &.zn-is-hidden {
            color: $fade;
          }

          &.zn-template-opening {
            color: $secondary;
          }

          &:first-child {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .zn-main-page {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      padding: 8px;
      padding-right: 40px;
      margin: 0 -8px 0 8px;
      background: white;
      overflow-x: hidden;
      overflow-y: auto;

      .zn-template-part {
        &:not(:first-child) {
          padding-top: 16px;

          input {
            padding: 4px 8px;
            border: 1px solid $fade;
            border-radius: 3px;
            outline: none;
            font-size: inherit;
            min-width: 280px;
            margin-bottom: 16px;
          }
        }

        & > div:first-child {
          margin-bottom: 8px;

          &.changed {
            color: $secondary;
          }
        }
      }

      .zn-template-title {
        input {
          padding: 4px 8px;
          border: 1px solid $fade;
          border-radius: 3px;
          outline: none;
          font-size: inherit;
          min-width: 280px;
        }
      }

      .zn-template-type {
        table {
          tr:not(:last-child) {
            td {
              padding-bottom: 16px;
            }
          }
        }

        .zn-switch {
          margin-left: 16px;
        }
      }

      .zn-template-screenshot {
        width: 280px;

        .zn-template-thumbnail {
          padding-bottom: 120%;
          background-position: 0 0;
          background-size: 100%;
          background-repeat: no-repeat;
          cursor: pointer;
          border: 1px solid $light;
          border-radius: 5px;

          &:hover {
            animation-duration: 2s;
            animation-name: scrollBackground;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            animation-timing-function: linear;
          }
        }

        @keyframes scrollBackground {
          0% {
            background-position: 0% 0%;
          }
          10% {
            background-position: 0% 0%;
          }
          90% {
            background-position: 0% 100%;
          }
          100% {
            background-position: 0% 100%;
          }
        }

        .zn-upload-image {
          padding: 0 12px;
          border: 2px dashed $fade;
          border-radius: 5px;
          cursor: pointer;
          margin-left: 16px;
          position: relative;

          input {
            display: none;
          }

          ::v-deep .material-icons-outlined {
            position: absolute;
            top: -1px;
            left: 0;
          }

          &:hover {
            border-color: $primary;
            ::v-deep .material-icons-outlined {
              color: $primary;
            }
          }
        }
      }

      .zn-template-data {
        height: auto;
        min-height: 600px;
        overflow: hidden;
      }

      .zn-template-preview {
        height: auto;
        min-height: 600px;
        overflow: hidden;
        padding-bottom: 16px;
      }
    }
  }
}
</style>
