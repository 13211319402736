<template>
  <div class="zn-shop-asset-editor" v-if="listAssetEditing.length">
    <div class="zn-shop-asset-editor-navigator">
      <ul>
        <li v-for="asset in listAssetEditing" :key="asset.fileName" :class="{ 'zn-active': assetEditing == asset.fileName }" @click="setActiveAssetEditing(asset.fileName)">
          <span class="zn-asset-name" :class="{'zn-asset-changed' : asset.changed}">{{ asset.fileName }}</span>
          <Icon name="close" @click.native.stop="handleCloseAsset(asset)" />
        </li>
      </ul>
    </div>

    <div class="zn-shop-asset-editor-content">
      <template v-for="asset in listAssetEditing">
        <CodeEditor :key="asset.fileName" v-bind="asset" v-if="assetEditing == asset.fileName" @save="$emit('save', $event)" @change="handleChangeAsset" />
      </template>
    </div>
  </div>
  <EmptyState v-else title="Select file in sidebar and edit now" />
</template>

<script>
import CodeEditor from '@/components/MonacoEditor.vue'
import { mapState, mapMutations } from 'vuex'

export default {
  components: {
    CodeEditor
  },
  computed: {
    ...mapState({
      listAssetEditing: state => state.shopAssets.listAssetEditing,
      assetEditing: state => state.shopAssets.assetEditing
    })
  },
  methods: {
    ...mapMutations({
      setActiveAssetEditing: 'shopAssets/setActiveAssetEditing',
      removeAssetEditing: 'shopAssets/removeAssetEditing',
      setChangedStateCurrentAsset: 'shopAssets/setChangedStateCurrentAsset',
      updateAssetData: 'shopAssets/updateAssetData'
    }),
    handleChangeAsset({ fileName, fileData }) {
      this.setChangedStateCurrentAsset(true)
      this.updateAssetData({ fileName, fileData })
    },
    handleCloseAsset(asset) {
      if (asset.changed) {
        this.$store.commit('confirm', {
          title: 'Are you sure?',
          message: `Confirm to leave without save file: <span class="zn-bold">${asset.fileName}</span>`,
          confirmText: 'Leave',
          status: 'danger',
          confirmHandler: () => {
            this.removeAssetEditing(asset.fileName)
          }
        })
        return
      }
      this.removeAssetEditing(asset.fileName)
    }
  }
}
</script>

<style lang="scss" scoped>
.zn-shop-asset-editor {
  display: flex;
  flex-direction: column;
  height: 100%;

  .zn-shop-asset-editor-navigator {
    background: white;
    margin-bottom: 1px;

    ul {
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;

      li {
        position: relative;
        flex: 0 0 auto;
        display: flex;
        align-items: flex-end;
        padding: 12px;
        cursor: pointer;

        &:not(:last-child) {
          border-right: 2px solid $fade;
        }

        &.zn-active {
          .zn-asset-name {
            color: $primary;
          }
        }

        .zn-asset-name {
          &.zn-asset-changed {
            color: $secondary;
          }
        }

        i {
          &:hover {
            color: red;
          }
        }
      }
    }
  }

  .zn-shop-asset-editor-content {
    height: calc(100% - 44px);
    padding-top: 4px;
  }
}
</style>
