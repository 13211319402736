import request from '@/utils/request.js'
import API_URL from '@/utils/sourceApiUrl'

const FAKE_DATA = [
  {
    key: 'zeno-fake1',
    name: 'Zeno Fake',
    category: '',
    thumbnailUrl: '',
    bc_element: '',
    sp_element: '',
    cms_element: ''
  },
  {
    key: 'zeno-fake2',
    name: 'Zeno Fake',
    category: '',
    thumbnailUrl: '',
    bc_element: '',
    sp_element: '',
    cms_element: ''
  },
  {
    key: 'zeno-fake3',
    name: 'Zeno Fake',
    category: '',
    thumbnailUrl: '',
    bc_element: '',
    sp_element: '',
    cms_element: ''
  }
]

const state = {
  sections: FAKE_DATA
}

const getters = {
  fetchedSection(state, getters, rootState) {
    return state.sections != FAKE_DATA
  }
}

const mutations = {
  setListSection(state, sections) {
    state.sections = sections
  },
  removeSection(state, id) {
    state.sections = state.sections.filter(section => section.recordId != id)
  }
 }

const actions = {
  async fetchListSection({ state, rootState, commit }) {
    const response = await request.get(`${API_URL.bigcommerce}/admin/sections`)
    commit('setListSection', response.sections)
    return response.sections
  },
  async getSectionData({ state, rootState, commit }, section) {
    if (section.bc_element && section.sp_element && section.cms_element) return

    const response = await request.get(`${API_URL.bigcommerce}/admin/sections/${section.recordId}`)
    const sectionInState = state.sections.find(item => item.recordId == section.recordId)
    sectionInState.bc_element = JSON.stringify(response.section.bc_element, null, 2)
    sectionInState.sp_element = JSON.stringify(response.section.sp_element, null, 2)
    sectionInState.cms_element = JSON.stringify(response.section.cms_element, null, 2)
    return 1
  },
  async uploadImage({}, { fileName, thumbnailBase64 }) {
    const response = await request.upload(`${API_URL.bigcommerce}/admin/sections/thumbnail?fileName=${fileName}`, thumbnailBase64)
    return response
  },
  async updateSectionValue({}, { recordId, sectionValue }) {
    const response = await request.put(`${API_URL.bigcommerce}/admin/sections/${recordId}`, sectionValue)
    return response
  },
  async reOrderListSection({}, body) {
    const response = await request.put(`${API_URL.bigcommerce}/admin/sections/re-order`, body)
    return response
  },
  async deleteSection({commit}, id) {
    const response = await request.delete(`${API_URL.bigcommerce}/admin/sections/${id}`)
    commit('removeSection', id)
  },
  async setHiddenSection({}, { id, value }) {
    const response = await request.delete(`${API_URL.bigcommerce}/admin/sections/${id}?value=${value}`)
    return response
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
