import jwtUtils from '@/utils/jwtUtils'

export default function({ next, router, store }) {
  const notExpired = store.state.auth.userInfo && store.state.auth.userInfo.exp * 1000 >= Date.now()
  const authenticated = jwtUtils.get() && notExpired

  if (!authenticated) {
    store.dispatch('auth/logout')
    return router.push('/login')
  }

  return next()
}
