<template>
  <div class="zn-container">
    <Sidebar />
    <router-view />

    <SizeLimit />
    <Confirm />
    <Alert />
    <Snackbar />
    <Prompt />
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar'
import Confirm from '@/components/common/Confirm'
import Alert from '@/components/common/Alert'
import Snackbar from '@/components/common/Snackbar'
import SizeLimit from '@/components/SizeLimit'
import Prompt from '@/components/common/Prompt.vue'

export default {
  components: {
    Sidebar,
    Confirm,
    Alert,
    Snackbar,
    SizeLimit,
    Prompt
  }
}
</script>

<style lang="scss" scoped>
.zn-container {
  display: flex;
  height: 100vh;

  .zn-sidebar {
    flex-basis: 280px;
  }

  .zn-main {
    flex-grow: 1;
    overflow-y: auto;
    background-color: rgba($light, 0.2);

    &.zn-processing {
      cursor: progress !important;

      * {
        cursor: progress !important;
      }
    }

    ::v-deep.zn-topbar {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 8px 16px;
      background: white;
      height: 60px;
    }
  }
}
</style>


<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

/* Reset CSS */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
	box-sizing: border-box;
}
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1.25;
	font-family: 'Poppins', sans-serif;
  color: $input;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
input {
	box-sizing: border-box;
	color: $input;
}
textarea {
	box-sizing: border-box;
	font-family: 'Poppins', sans-serif;
	resize: none;
	color: $input;
}
button {
  font-family: 'Poppins', sans-serif;
}

// Tooltip
/* Tooltip */
.tooltip {
  font-size: 12px;
  display: block !important;
  z-index: 99999;

  &[x-placement^='top'] {
    margin-bottom: 5px;
    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='bottom'] {
    margin-top: 5px;
    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='right'] {
    margin-left: 5px;
    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^='left'] {
    margin-right: 5px;
    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }

  &.popover {
    .popover-inner {
      background: #f9f9f9;
      color: black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
    }

    .popover-arrow {
      border-color: #f9f9f9;
    }
  }

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }
}

/* Utils */
.zn-dense {
  margin: 0;
  padding: 0;
}

.zn-light {
  font-weight: 300;
}

.zn-bold {
  font-weight: 500;
}

.zn-uppercase {
	text-transform: uppercase;
}

.zn-noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.zn-loading {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  color: transparent !important;
  background: linear-gradient(to right, #F6F6F6 8%, #E6E6E6 18%, #F6F6F6 33%);
  background-size: 800px 104px;
  position: relative;
}

@keyframes placeHolderShimmer{
  0%{
      background-position: -468px 0
  }
  100%{
      background-position: 468px 0
  }
}

/* Customize website's scrollbar like Mac OS */
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba($fade, 0.25) #fff;
}

/* Not contrain supports in Firefox and IE */
::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-left: 1px solid rgba($fade, 0.2);
}

::-webkit-scrollbar-thumb {
  background-color: rgba($fade, 0.5);
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 16px;
  border-left-width: 5px;

  &:hover {
    background-color: rgba($fade, 1);
  }
}
</style>
