<template>
  <div class="zn-context">
    <span ref="handle" class="zn-context-handle" @click.stop="visible = !visible">
      <slot name="handle"></slot>
    </span>
    <span ref="view" class="zn-context-view" v-show="visible" v-click-outside="closeContext" @click.stop="">
      <slot name="context"></slot>
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false
    }
  },
  methods: {
    closeContext() {
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.zn-context {
  display: inline-block;
}
</style>
