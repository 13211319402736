<template>
  <transition name="bounce" tag="div" class="zn-confirm-modal-wrapper">
    <div class="zn-confirm" v-if="$store.state.confirm" @click.stop="cancelHandler">
        <div class="zn-confirm-modal">
          <h3 class="zn-bold">{{$store.state.confirm.title}}</h3>
          <p v-html="$store.state.confirm.message"></p>
          <div class="zn-confirm-actions">
            <button :class="`zn-confirm-positive ${$store.state.confirm.status}`" @click="confirmHandler">{{$store.state.confirm.confirmText || 'Confirm'}}</button>
            <button class="zn-confirm-negative" @click.stop="cancelHandler">{{$store.state.confirm.cancelText || 'Cancel'}}</button>
          </div>
        </div>
    </div>
  </transition>
</template>

<script>
export default {
  methods: {
    confirmHandler() {
      let confirmHandler = this.$store.state.confirm.confirmHandler
      this.$store.commit('confirm', null)
      if (confirmHandler) {
        confirmHandler()
      }
    },
    cancelHandler(e) {
      if (!e.target.querySelector('.zn-confirm-modal') && !e.target.classList.contains('zn-confirm-negative')) {
        return
      }

      let cancelHandler = this.$store.state.confirm.cancelHandler
      this.$store.commit('confirm', null)
      if (cancelHandler) {
        cancelHandler()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.zn-confirm {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(black, 0.3);
  overflow: hidden;

  &.bounce-enter-active {
    animation: bounce 0.15s;
  }

  &.bounce-leave-active {
    animation: bounce 0.15s reverse;
  }

  @keyframes bounce {
    0% {
      opacity: 0;
      transform: scale(1.1);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .zn-confirm-modal {
    background-color: white;
    display: inline-block;
    position: absolute;
    top: calc(50% - 60px);
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 24px;
    border-radius: 5px;
    width: 480px;

    h3 {
      font-size: 22px;
    }

    p {
      margin: 32px 0;
      font-size: 16px;
      opacity: 0.9;
      line-height: 1.5;
    }

    .zn-confirm-actions {
      display: flex;
      justify-content: space-between;

      button {
        flex-basis: calc(50% - 8px);
        padding: 12px 0;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        outline: none;

        &:hover {
          opacity: 0.9;
        }

        &.zn-confirm-positive {
          background-color: $primary;
          color: white;

          &.danger {
            background-color: $danger;
          }
        }

        &.zn-confirm-negative {
          background-color: $light;
        }
      }
    }
  }
}
</style>