<template>
  <transition name="bounce" tag="div" class="zn-alert-modal-wrapper">
    <div class="zn-alert" v-if="$store.state.alert" @click.stop="confirmHandler">
        <div class="zn-alert-modal" @click.stop="">
          <h3 class="zn-bold">{{$store.state.alert.title}}</h3>
          <p v-html="$store.state.alert.message"></p>
          <div class="zn-alert-actions">
            <button :class="`zn-alert-positive ${$store.state.alert.status}`" @click="confirmHandler">{{$store.state.alert.confirmText || 'OK'}}</button>
          </div>
        </div>
    </div>
  </transition>
</template>

<script>
export default {
  methods: {
    confirmHandler() {
      let confirmHandler = this.$store.state.alert.confirmHandler
      this.$store.commit('alert', null)
      if (confirmHandler) {
        confirmHandler()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.zn-alert {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(black, 0.3);
  overflow: hidden;

  &.bounce-enter-active {
    animation: bounce 0.15s;
  }

  &.bounce-leave-active {
    animation: bounce 0.15s reverse;
  }

  @keyframes bounce {
    0% {
      opacity: 0;
      transform: scale(1.1);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .zn-alert-modal {
    background-color: white;
    display: inline-block;
    position: absolute;
    top: calc(50% - 60px);
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 24px;
    border-radius: 5px;
    width: 480px;

    h3 {
      font-size: 22px;
    }

    p {
      margin: 32px 0;
      font-size: 16px;
      opacity: 0.9;
      line-height: 1.5;
    }

    .zn-alert-actions {
      display: flex;
      justify-content: center;

      button {
        flex-basis: calc(50% - 8px);
        padding: 12px 0;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        outline: none;
        background-color: $primary;
        color: white;
      
        &.danger {
          background-color: $danger;
        }
      }
    }
  }
}
</style>