<template>
  <main class="zn-main">
    <div class="zn-category-container">
      <div class="zn-top-bar">
        <div class="zn-top-bar-left">
          <div class="zn-category-name">Dashboard</div>
          <div class="zn-category-desc">General information about Zeno Page Builder.</div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import auth from '@/middleware/auth'

export default {
  middleware: [auth],
}
</script>

<style lang="scss" scoped>
.zn-category-container {
  width: 100%;
  max-width: 980px;
  margin: auto;
  padding: 43px 32px 32px 32px; 

  .zn-top-bar {
    display: flex;
    justify-content: space-between;

    .zn-top-bar-left {
      .zn-category-name {
        font-weight: 500;
        font-size: 22px;
      }
    
      .zn-category-desc {
        font-size: 14px;
        margin-top: 8px;
        color: $fade;
      }
    }
  }
}
</style>