<template>
  <transition name="bounce" tag="div" class="zn-confirm-modal-wrapper">
    <div class="zn-prompt" v-if="$store.state.prompt">
      <div class="zn-promt-mark" @click.stop="cancelHandler"></div>
      <form class="zn-prompt-modal" @submit.prevent="confirmHandler">
        <h3 class="zn-bold">{{ $store.state.prompt.title }}</h3>
        <p v-html="$store.state.prompt.message"></p>
        <input
          ref="input"
          autocomplete="off"
          required
          :type="$store.state.prompt.type || 'text'"
          :value="$store.state.prompt.defaultValue || ''"
          :placeholder="$store.state.prompt.placeholder || ''"
        />
        <div class="zn-prompt-actions">
          <button type="submit" class="zn-prompt-positive">{{ $store.state.prompt.confirmText || 'Confirm' }}</button>
          <button class="zn-prompt-negative" @click.stop="cancelHandler">{{ $store.state.prompt.cancelText || 'Cancel' }}</button>
        </div>
      </form>
    </div>
  </transition>
</template>

<script>
export default {
  methods: {
    confirmHandler() {
      if (!this.$store.state.prompt) return

      let confirmHandler = this.$store.state.prompt.confirmHandler
      this.$store.commit('prompt', null)
      if (confirmHandler) {
        confirmHandler(this.$refs.input.value)
      }
    },
    cancelHandler(e) {
      if (!this.$store.state.prompt) return

      let cancelHandler = this.$store.state.prompt.cancelHandler
      this.$store.commit('prompt', null)
      if (cancelHandler) {
        cancelHandler()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.zn-prompt {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  background-color: rgba(black, 0.3);
  z-index: 9999;

  &.bounce-enter-active {
    animation: bounce 0.15s;
  }

  &.bounce-leave-active {
    animation: bounce 0.15s reverse;
  }

  @keyframes bounce {
    0% {
      opacity: 0;
      transform: scale(1.1);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .zn-promt-mark {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .zn-prompt-modal {
    background-color: white;
    display: inline-block;
    position: absolute;
    top: calc(50% - 60px);
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 24px;
    border-radius: 5px;
    width: 480px;

    h3 {
      font-size: 24px;
    }

    p {
      margin: 32px 0 16px 0;
      font-size: 16px;
      opacity: 0.9;
      line-height: 1.5;
    }

    input {
      width: 100%;
      padding: 8px;
      font-size: 16px;
      margin-top: 1px;
      margin-bottom: 32px;
    }

    .zn-prompt-actions {
      display: flex;
      justify-content: space-between;

      button {
        flex-basis: calc(50% - 8px);
        padding: 16px 0;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        outline: none;

        &:hover {
          opacity: 0.9;
        }

        &.zn-prompt-positive {
          background-color: $primary;
          color: white;
        }

        &.zn-prompt-negative {
          background-color: $light;
        }
      }
    }
  }
}
</style>
