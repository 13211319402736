<template>
  <div class="zn-shop-asset-sidebar">
    <div class="zn-shop-asset-quick-action">
      <Search v-model="keySearchAsset" allowClear placeholder="Search files..." />
      <div class="zn-shop-asset-toggle-all zn-noselect" @click="handleToggleAll">
        <Icon :name="expandAll ? 'unfold_less' : 'unfold_more'" />
      </div>
    </div>

    <div class="zn-shop-asset-category-container" v-if="listCategory.length">
      <ShopAssetCategory
        ref="category"
        v-for="category in listCategory"
        v-bind="category"
        :key="category.name"
        @toggle="forceUpdateToggleAllState"
        @openAsset="activeOrFetchShopAsset"
      />
    </div>

    <EmptyState v-if="listCategory.length == 0" title="No asset available" />
  </div>
</template>

<script>
import ShopAssetCategory from './ShopAssetCategory.vue'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    ShopAssetCategory
  },
  data() {
    return {
      selfListCategory: [],
      expandAll: false,
      keySearchAsset: ''
    }
  },
  computed: {
    ...mapState({
      assets: state => state.shopAssets.assets
    }),
    listCategory() {
      let clone = [...this.selfListCategory]
      clone = this.filterAssetByQuery(clone)
      return clone
    }
  },
  watch: {
    assets: {
      handler() {
        if (this.assets.length == 0) return

        const categories = {
          layout: [],
          templates: [],
          sections: [],
          snippets: [],
          assets: []
        }
        this.assets.forEach(asset => {
          const combinedValue = asset.split('/')
          const name = combinedValue.shift()

          if (!categories[name]) categories[name] = []
          categories[name].push(combinedValue.join('/'))
        })

        const listCategory = []
        for (const category in categories) {
          listCategory.push({ name: category, assets: categories[category] })
        }
        this.selfListCategory = listCategory
      }
    },
    keySearchAsset() {
      this.expandAll = true
      this.$refs['category']?.forEach(category => category.forceToggle(true))
    }
  },
  methods: {
    ...mapActions({
      activeOrFetchShopAsset: 'shopAssets/activeOrFetchShopAsset',
    }),
    filterAssetByQuery(clone) {
      let stringSearch = this.keySearchAsset
        .replace(/\s/g, '')
        .split('')
        .join('\\w*')

      if (stringSearch == '') return clone

      let queryRegex = new RegExp(stringSearch, 'gmi')

      return clone
        .map(category => {
          if (category.name.match(queryRegex)) return category

          const assetsMatch = category.assets.filter(asset => asset.match(queryRegex))
          return {
            name: category.name,
            assets: assetsMatch
          }
        })
        .filter(category => category.assets.length)
    },
    forceUpdateToggleAllState() {
      const listCategory = this.$refs['category']
      const totalValueState = listCategory
        .map(category => (category.expand ? 1 : 0))
        .reduce((sum, value) => (sum += value), 0)

      if (totalValueState == 0) {
        // All category is collapse
        this.expandAll = false
      } else if (totalValueState == listCategory.length) {
        // All category is expand
        this.expandAll = true
      }
    },
    handleToggleAll() {
      this.expandAll = !this.expandAll
      this.$refs['category']?.forEach(category => category.forceToggle(this.expandAll))
    }
  }
}
</script>

<style lang="scss" scoped>
.zn-shop-asset-sidebar {
  padding: 8px;
  max-width: 280px;
  flex: 0 0 280px;
  background: white;

  .zn-shop-asset-quick-action {
    display: flex;

    .zn-search {
      flex: 1 1 auto;
      margin-right: 4px;
    }

    .zn-shop-asset-toggle-all {
      flex: 0 0 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
    }
  }

  .zn-shop-asset-category-container {
    height: calc(100% - 42px);
    margin-right: -8px;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .zn-empty {
    overflow: hidden;
  }
}
</style>
