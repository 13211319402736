import request from '@/utils/request.js'
import API_URL from '@/utils/sourceApiUrl'
import jwtUtils from '../utils/jwtUtils'

const state = {
  userInfo: jwtUtils.get() ? jwtUtils.decode(jwtUtils.get()) : null
}

const getters = {
  isAdmin(state, getters) {
    return state.userInfo?.roles?.includes('admin')
  }
}

const mutations = {
  setUserInfo(state, info) {
    state.userInfo = info
  }
}

const actions = {
  async login({ state, commit }, token) {
    const response = await request.get(`${API_URL.bigcommerce}/admin/auth`, { token: btoa(token) })
    jwtUtils.save(response.token)
    commit('setUserInfo', jwtUtils.decode(jwtUtils.get()))
  },
  async logout({ state, commit }) {
    jwtUtils.remove()
    commit('setUserInfo', null)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
