<template>
  <div class="zn-monaco-editor">
    <div ref="monacoEditor"></div>
  </div>
</template>

<script>
import * as monaco from 'monaco-editor'
import * as emmetMonaco from 'emmet-monaco-es'

export default {
  props: {
    fileName: String,
    fileData: String,
    theme: {
      type: String,
      default: 'vs-dark'
    }
  },
  data() {
    return {
      monacoInstance: null
    }
  },
  mounted() {
    this.createCodeEditor()
    this.registerTrackingChangeContent()
    this.registerSaveFile()
  },
  methods: {
    getLanguage(fileName) {
      const parts = fileName.split('.')
      const extension = parts[parts.length - 1]

      const mapLanguage = {
        js: 'javascript',
        py: 'python',
        ts: 'typescript'
      }

      if (Object.hasOwnProperty.call(mapLanguage, extension)) {
        return mapLanguage[extension]
      } else return extension
    },
    getValue() {
      return this.monacoInstance && this.monacoInstance.getValue()
    },
    createCodeEditor() {
      emmetMonaco.emmetHTML(monaco)
      this.monacoInstance = monaco.editor.create(this.$refs.monacoEditor, {
        value: this.fileData || '',
        language: this.getLanguage(this.fileName),
        theme: this.theme
      })
    },
    registerTrackingChangeContent() {
      this.monacoInstance.getModel().onDidChangeContent(e => {
        this.$emit('change', { fileName: this.fileName, fileData: this.getValue() })
      })
    },
    registerSaveFile() {
      this.monacoInstance.addAction({
        id: `monaco-save-${this.fileName}`,
        label: `monaco-label-${this.fileName}`,
        keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S],
        run: ed => {
          this.$emit('save', this.fileName)
          return null
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.zn-monaco-editor {
  height: 100%;

  & > div {
    height: 100%;
  }
}
</style>
