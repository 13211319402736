function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  if (!subsequentMiddleware) return context.next

  return (...parameters) => {
    context.next(...parameters)
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}

export default (router, store) => {
  return function(to, from, next) {
    const toMiddleware = to.matched[to.matched.length - 1].components.default.middleware
    if (!toMiddleware) return next()

    const middleware = Array.isArray(toMiddleware) ? toMiddleware : [toMiddleware]
    const context = { from, next, router, to , store}
    const nextMiddleware = nextFactory(context, middleware, 1)

    return middleware[0]({ ...context, next: nextMiddleware })
  }
}
