<template>
  <main class="zn-main">
    <div class="zn-category-container">
      <div class="zn-top-bar">
        <div class="zn-top-bar-left">
          <div class="zn-category-name">Shop manager</div>
          <div class="zn-category-desc">Login to users's Zeno dashboard for supporting purpose.</div>
        </div>
      </div>

      <div class="zn-main">
        <div class="zn-toolbar">
          <div class="zn-toolbar-left">
            <select class="zn-toolbar-action zn-select-platform" @change="handleChangePlatform" :value="platform" :disabled="loading">
              <option value="shopify">Shopify</option>
              <option value="bigcommerce">BigCommerce</option>
            </select>

            <select class="zn-toolbar-action zn-select-filter-id" @change="handleChangeFilterId" :value="filterId" :disabled="loading">
              <option value="lastUpdated">Last updated</option>
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </select>
          </div>

          <Search v-model="query" allowClear @input="pagination = 1" />
        </div>

        <div class="zn-content">
          <Shop
            v-for="(shop, index) in listShops"
            v-show="Math.ceil((index + 1) / PAGINATION_SIZE) == pagination"
            :key="index"
            :loading="loading"
            :shop="shop"
          />
          <Pagination v-if="!loading" v-model="pagination" :size="Math.ceil(listShops.length / PAGINATION_SIZE)" :total="listShops.length" />
          <EmptyState v-if="listShops.length == 0" title="No shop avaiable" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import auth from '@/middleware/auth'
import author from '@/middleware/author'
import Shop from '@/components/Shop.vue'
import Search from '@/components/common/Search.vue'
import EmptyState from '@/components/common/EmptyState.vue'
import Pagination from '@/components/Pagination'
import PAGINATION_SIZE from '@/config/paginationSize.js'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import deepClone from 'lodash.clonedeep'

export default {
  middleware: [auth, author(['admin', 'dev', 'support'])],
  components: {
    Shop,
    Search,
    EmptyState,
    Pagination
  },
  data() {
    return {
      query: '',
      loading: false,
      pagination: 1,
      PAGINATION_SIZE
    }
  },
  computed: {
    ...mapState({
      sourceShops: state => state.shops.listShops,
      platform: state => state.platform,
      filterId: state => state.shops.filterId
    }),
    ...mapGetters({
      fetchedListShops: 'shops/fetchedListShops'
    }),
    listShops() {
      let clone = deepClone(this.sourceShops[this.platform])
      clone = this.filterByKeySearch(clone)
      clone = this.filterById(clone)
      return clone
    }
  },
  watch: {
    fetchedListShops(val) {
      if (!val) {
        this.fetchData()
      }
    }
  },
  async created() {
    if (!this.fetchedListShops) {
      this.fetchData()
    }
  },
  methods: {
    ...mapActions({
      fetchListShop: 'shops/fetchListShop'
    }),
    ...mapMutations({
      setPlatform: 'setPlatform',
      setFilterId: 'shops/setFilterId'
    }),
    filterById(clone) {
      if (this.filterId == 'lastUpdated') return clone

      if (this.filterId == 'desc') return clone.sort((a, b) => b.id - a.id)

      return clone.sort((a, b) => a.id - b.id)
    },
    filterByKeySearch(clone) {
      if (this.query.includes('.myshopify.com')) return clone.filter(shop => this.query.includes(shop.name))
      if (this.query.includes('.mybigcommerce.com')) return clone.filter(shop => this.query.includes(shop.storeHash))
      if (parseInt(this.query) && !isNaN(this.query)) return clone.filter(shop => shop.id == parseInt(this.query))
      
      if (this.query == ':starter') return clone.filter(item => item.plan == 'starter')
      if (this.query == ':pro')  return clone.filter(item => ['pro', 'annual'].includes(item.plan))
      if (this.query == ':paid') return clone.filter(item => ['pro', 'annual', 'starter'].includes(item.plan))

      return clone.filter(shop => JSON.stringify(shop).includes(this.query))
    },
    async fetchData() {
      this.loading = true
      try {
        await this.fetchListShop()
      } catch (err) {
        console.log('Warn 6:',err)
        this.$store.commit('notify', 'Cannot get list shop, see more in devtool')
      }
      this.loading = false
    },
    handleChangePlatform(e) {
      this.setPlatform(e.target.value)
      this.pagination = 1
    },
    handleChangeFilterId(e) {
      this.setFilterId(e.target.value)
      this.pagination = 1
    }
  }
}
</script>

<style lang="scss" scoped>
.zn-category-container {
  width: 100%;
  max-width: 980px;
  margin: auto;
  padding: 43px 32px 32px 32px;

  .zn-top-bar {
    display: flex;
    justify-content: space-between;

    .zn-top-bar-left {
      .zn-category-name {
        font-weight: 500;
        font-size: 22px;
      }

      .zn-category-desc {
        font-size: 14px;
        margin-top: 8px;
        color: $fade;
      }
    }
  }

  .zn-main {
    margin-top: 64px;
    .zn-toolbar {
      margin-bottom: 24px;
      position: relative;
      display: flex;
      justify-content: space-between;

      .zn-toolbar-action {
        &:not(:first-child) {
          margin-left: 12px;
        }
      }

      .zn-select-platform,
      .zn-select-filter-id {
        width: 200px;
        padding: 12px;
        font-size: 14px;
        border: 1px solid $light;
        border-radius: 5px;
        outline: none;
        transition: border-color 0.5s;
      }

      .zn-search {
        width: 280px;
      }
    }
  }
}
</style>
