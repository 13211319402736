<template>
  <div class="zn-pagination zn-noselect" v-if="size > 1">
    <div class="zn-pagination-total" v-if="total">Total {{ total }} items &nbsp;</div>
    <div
      class="zn-pagination-page"
      v-show="[1, size, value - 2, value - 1, value, value + 1, value + 2].includes(page)"
      v-for="page in size"
      :key="page"
      :class="{ active: page == value, more: isMore(page) }"
      @click="paginate(page)"
    >
      {{ isMore(page) ? '...' : page }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Number,
    size: Number,
    total: Number
  },
  methods: {
    paginate(page) {
      if (!this.isMore(page)) {
        this.$emit('input', page)
      }
    },
    isMore(page) {
      return (page == this.value - 2 && page > 1) || (page == this.value + 2 && page < this.size)
    }
  }
}
</script>

<style lang="scss" scoped>
.zn-pagination {
  display: flex;
  justify-content: center;
  margin: 24px 0;
  font-size: 18px;

  .zn-pagination-total {
    display: flex;
    align-items: center;
  }

  .zn-pagination-page {
    margin: 0 8px;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.active {
      color: $primary;
    }

    &.more {
      cursor: default;
    }

    &.hidden {
      display: none;
    }
  }
}
</style>
