import jwtUtils from './jwtUtils'

export default {
  get: async (path, params) => {
    let paramStr = ''
    for (let param in params) {
      if (paramStr == '') {
        paramStr += `?${param}=${params[param]}`
      } else {
        paramStr += `&${param}=${params[param]}`
      }
    }

    let response = await fetch(`${path}${paramStr}`, {
      method: 'get',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtUtils.get()
      }
    })
    let data = await response.json()

    if (!response.ok) {
      throw new Error(data.message)
    } 

    return data
  },
  post: async (path, body) => {
    let response = await fetch(path, {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtUtils.get()
      },
      body: JSON.stringify(body)
    })
    let data = await response.json()

    if (!response.ok) {
      throw new Error(data.message)
    } 

    return data
  },
  put: async (path, body) => {
    let response = await fetch(path, {
      method: 'put',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtUtils.get()
      },
      body: JSON.stringify(body)
    })
    let data = await response.json()

    if (!response.ok) {
      throw new Error(data.message)
    } 

    return data
  },
  delete: async (path, body) => {
    let response = await fetch(path, {
      method: 'delete',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtUtils.get()
      },
      body: JSON.stringify(body)
    })
    let data = await response.json()

    if (!response.ok) {
      throw new Error(data.message)
    } 

    return data
  },
  upload: async (path, dataUri) => {
    const file = await fetch(dataUri).then(res => res.blob())
    const formData = new FormData()
    formData.append('file', file)

    let response = await fetch(path, {
      method: 'post',
      credentials: 'include',
      headers: {
        'Authorization': 'Bearer ' + jwtUtils.get()
      },
      body: formData
    })
    let data = await response.json()

    if (!response.ok) {
      throw new Error(data.message)
    } 

    return data
  },
}