<template>
  <main class="zn-main" :class="{ 'zn-processing': loading }" >
    <div class="zn-topbar">
      <div class="zn-topbar-left"></div>

      <div class="zn-topbar-right" v-if="openingSection">
        <template v-if="!isCreate">
          <Button v-if="openingSection.isHidden" type="primary" @click="handleRevertSection" :disabled="isDeleting" :loading="isDeleting" class="mr-2">{{ isDeleting ? 'Reverting' : 'Revert' }}</Button>
          <Button v-else type="danger" @click="handleDeleteSection" :disabled="isDeleting" :loading="isDeleting" class="mr-2">{{ isDeleting ? 'Deleting' : 'Delete' }}</Button>
        </template>
        <Button type="primary" :disabled="!openingSection.changed.length || isSaving" :loading="isSaving" @click="handleSaveSection">{{ isSaving ? 'Saving' : 'Save' }}</Button>
      </div>
      <div class="zn-topbar-right" v-if="reOrder">
        <Button type="primary" :disabled="(openingSection && !openingSection.changed.length) || isSaving" :loading="isSaving" @click="handleSaveSection">{{ isSaving ? 'Saving' : 'Save' }}</Button>
      </div>
    </div>

    <div class="zn-page-container">
      <div class="zn-page-sidebar">
        <Search v-model="keySearch" allowClear placeholder="Search section..." />
        <div class="zn-section-container">
          <div class="zn-section-item zn-text-primary" :class="{ 'zn-loading': loading }" @click="handleCreateSection">
            <Icon name="add" />
            <span>Create new section</span>
          </div>
          <Draggable :list="sections" :disabled="openingSection || keySearch" v-bind="dragOptions" @change="reOrder=true" >
            <template v-for="section in sectionFiltered">
              <div
                class="zn-section-item"
                :key="section.key"
                :class="{ 'zn-loading': loading, 'zn-section-opening': openingSection && openingSection.recordId == section.recordId, 'zn-is-hidden': section.isHidden }"
                @click="!reOrder && handleOpenSection(section)"
              >
                {{ section.name }}
              </div>
            </template>
          </Draggable>
        </div>
      </div>
      <div class="zn-main-page" v-if="openingSection">
        <div class="zn-section-part zn-section-title">
          <div :class="{ changed: openingSection.changed.includes('name') }">Section name</div>
          <TextInput placeholder="Enter section name" :value="openingSection.name" @input="handleChangeSectionName" style="width: 280px;" />
        </div>

        <div class="zn-section-part zn-section-type">
          <table>
            <tr>
              <td>In Shopify</td>
              <td><Switcher name="ahhi" :value="openingSection.inShopify" @input="handleSwitchType('inShopify', $event)" /></td>
            </tr>

            <tr>
              <td>In BigCommerce</td>
              <td><Switcher name="ahhi" :value="openingSection.inBigCommerce" @input="handleSwitchType('inBigCommerce', $event)" /></td>
            </tr>
          </table>
        </div>

        <div class="zn-section-part zn-section-category">
          <div :class="{ changed: openingSection.changed.includes('category') }">Section category</div>
          <TextInput placeholder="Enter section category" :value="openingSection.category" @input="handleChangeSectionCategory" style="width: 280px;" />
        </div>

        <div class="zn-section-part zn-section-screenshot">
          <div :class="{ changed: openingSection.changed.includes('thumbnail') }">
            <span>Section thumbnail</span>
            <label class="zn-upload-image">
              <Icon name="add" />
              <input type="file" accept="image/*" @change="handleUploadLocalImage" />
            </label>
          </div>
          <div class="zn-section-thumbnail" :style="{ backgroundImage: `url(${openingSection.thumbnailUrl})` }" @click="handleOpenThumbnail"></div>
        </div>

        <div class="zn-section-part zn-section-data">
          <div :class="{ changed: openingSection.changed.includes('bc_element') }">Bigcommerce data</div>
          <CodeEditor :fileName="`bc_element.json`" :fileData="openingSection.bc_element" @change="handleChangeSectionContent($event, 'bc_element')" :key="updateKey" />
        </div>

        <div class="zn-section-part zn-section-data">
          <div :class="{ changed: openingSection.changed.includes('sp_element') }">Shopify data</div>
          <CodeEditor :fileName="`sp_element.json`" :fileData="openingSection.sp_element" @change="handleChangeSectionContent($event, 'sp_element')" :key="updateKey" />
        </div>
      </div>
      <div class="zn-main-page" v-else>
        <EmptyState v-if="!openingSection" title="Select section and edit now" />
      </div>
    </div>
  </main>
</template>

<script>
import auth from '@/middleware/auth'
import author from '@/middleware/author'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import CodeEditor from '@/components/MonacoEditor.vue'
import clonedeep from 'lodash.clonedeep'
import * as snippet from '@/utils/snippet'
import Draggable from 'vuedraggable'
import Switcher from '@/components/common/Switcher.vue'

export default {
  middleware: [auth, author(['admin', 'dev'])],
  components: {
    CodeEditor,
    Draggable,
    Switcher
  },
  data() {
    return {
      keySearch: '',
      loading: false,
      openingSection: null,
      isSaving: false,
      updateKey: 1,
      isCreate: false,
      isDeleting: false,
      dragOptions: {
        sort: true,
        animation: 150,
        chosenClass: "zn-dragable-chose",
        dragClass: "zn-dragable-drag"
      },
      reOrder: false
    }
  },
  computed: {
    ...mapState({
      sections: state => state.section.sections
    }),
    ...mapGetters({
      fetchedSection: 'section/fetchedSection'
    }),
    sectionFiltered() {
      return this.sections.filter(item => item.name.toLowerCase().includes(this.keySearch.toLowerCase()))
    }
  },
  watch: {
    fetchedSection(val) {
      if (!val) {
        this.fetchData()
      }
    }
  },
  async created() {
    if (!this.fetchedSection) {
      this.fetchData()
    }
  },
  methods: {
    ...mapActions({
      fetchListSection: 'section/fetchListSection',
      getSectionData: 'section/getSectionData',
      uploadImage: 'section/uploadImage',
      updateSectionValue: 'section/updateSectionValue',
      reOrderListSection: 'section/reOrderListSection',
      setHiddenSection: 'section/setHiddenSection'
    }),
    async fetchData() {
      this.loading = true
      try {
        await this.fetchListSection()
      } catch (err) {
        console.log('Warn 7:', err)
        this.$store.commit('notify', 'Cannot get list section, see more in devtool')
      }
      this.loading = false
    },
    handleOpenSection(section) {
      const openSection = async () => {
        this.isCreate = false
        this.loading = true
        const appendTasks = [this.getSectionData(section)]
        await Promise.all(appendTasks)
        this.openingSection = clonedeep(section)
        if (!this.openingSection.thumbnailUrl.includes('blob:http')) {
          this.openingSection.thumbnailUrl += `?t=${Date.now()}`
        }
        this.openingSection.changed = []
        this.updateKey++
        this.loading = false
      }

      if (this.openingSection && this.openingSection.changed.length) {
        this.$store.commit('confirm', {
          title: 'Are you sure?',
          message: 'Confirm to leave without save content',
          confirmText: 'Leave',
          status: 'danger',
          confirmHandler: () => {
            openSection()
          }
        })
        return
      }

      openSection()
    },
    handleOpenThumbnail() {
      window.open(this.openingSection.thumbnailUrl)
    },
    triggerChangeType(type) {
      if (!this.openingSection.changed.includes(type)) this.openingSection.changed.push(type)
    },
    handleChangeSectionName(value) {
      this.openingSection.name = value
      this.openingSection.key = value.toLowerCase().replace(/[^a-z\d]+/gi, '-')
      this.triggerChangeType('name')
    },
    handleSwitchType(field, value) {
      this.openingSection[field] = value
      this.triggerChangeType('category')
    },
    handleChangeSectionCategory(value) {
      this.openingSection.category = value
      this.triggerChangeType('category')
    },
    handleChangeSectionContent({ fileName, fileData }, type) {
      const field = fileName.split('.')[0]
      this.openingSection[field] = fileData
      this.triggerChangeType(type)
    },
    handleCreateSection() {
      const createSection = async () => {
        this.isCreate = true
        this.openingSection = {
          key: '',
          name: '',
          category: '',
          thumbnailUrl: '',
          bc_element: '',
          sp_element: '',
          cms_element: ''
        }
        this.openingSection.changed = []
        this.updateKey++
      }

      if (this.openingSection && this.openingSection.changed.length) {
        this.$store.commit('confirm', {
          title: 'Are you sure?',
          message: 'Confirm to leave without save content',
          confirmText: 'Leave',
          status: 'danger',
          confirmHandler: () => {
            createSection()
          }
        })
        return
      }

      createSection()
    },
    async handleReorderSections() {
      if (!this.reOrder) return
      this.isSaving = true

      const tempList = this.sections.map((item, index) => ({id: item.recordId, order: index}))
      await this.reOrderListSection({ sections: tempList })
      await this.fetchData()

      this.reOrder = false
      this.isSaving = false
    },
    async handleSaveNewSection() {
      if (!this.openingSection.thumbnailUrl) return this.$store.commit('notify', 'Please upload thumbnail image')

      const { recordId, name, key, category, bc_element, sp_element, cms_element, inShopify, inBigCommerce } = this.openingSection
      const sectionValue = { name, key, category, order: this.sections.length, inShopify, inBigCommerce }

      try {
        sectionValue.bc_element = JSON.parse(bc_element)
        sectionValue.sp_element = JSON.parse(sp_element)
        sectionValue.cms_element = JSON.parse(cms_element)
      } catch (e) {
        console.log('Warn 8', e)
        return this.$store.commit('notify', 'JSON format incorrect')
      }
      this.isSaving = true

      const tasks = []
      tasks.push(this.uploadImage({ fileName: 'undefined', thumbnailBase64: this.openingSection.thumbnailBase64 }))
      const response = await Promise.all(tasks)
      sectionValue.thumbnailUrl = response[0].thumbnailUrl
      await this.updateSectionValue({ recordId, sectionValue })

      this.openingSection = null
      this.isCreate = false
      this.isSaving = false
      this.fetchData()
    },
    async handleDeleteSection() {
      this.$store.commit('confirm', {
        title: `Are you sure delete section?`,
        message: `Section <b>${this.openingSection.name}</b> will be hide in Dashboard and Editor.`,
        status: 'danger',
        confirmText: 'Delete',
        confirmHandler: async () => {
          this.isDeleting = true
          try {
            await this.setHiddenSection({ id: this.openingSection.recordId, value: true })
            this.openingSection = null
            this.fetchData()
          } catch (err) {
            this.$store.commit('notify', 'Cannot delete section, please see more in devtool')
            console.log('Warn 5:',err)
          }
          this.isDeleting = false
        }
      })
    },
    async handleRevertSection() {
      this.$store.commit('confirm', {
        title: `Are you sure revert section?`,
        message: `Section <b>${this.openingSection.name}</b> will be show in Dashboard and Editor.`,
        confirmText: 'Revert',
        confirmHandler: async () => {
          this.isDeleting = true
          try {
            await this.setHiddenSection({ id: this.openingSection.recordId, value: false })
            this.openingSection = null
            this.fetchData()
          } catch (err) {
            this.$store.commit('notify', 'Cannot delete section, please see more in devtool')
            console.log('Warn 5:',err)
          }
          this.isDeleting = false
        }
      })
    },
    async handleSaveSection() {
      if (this.reOrder) return this.handleReorderSections()
      const duplicateSectionName = this.sections.find(item => item.recordId != this.openingSection.recordId && (item.name == this.openingSection.name || item.key == this.openingSection.key))
      if (duplicateSectionName) return this.$store.commit('notify', 'Section name must unique')
      if (!this.openingSection.name || !this.openingSection.key) return this.$store.commit('notify', 'Section name cannot empty')
      if (this.isCreate) return this.handleSaveNewSection()

      const tasks = []
      const originSectionValue = this.sections.find(item => item.recordId == this.openingSection.recordId)
      const { recordId, name, key, category, bc_element, sp_element, cms_element, order, inShopify, inBigCommerce } = this.openingSection
      const sectionValue = {
        name, key, category, order,
        thumbnailUrl: originSectionValue.thumbnailUrl,
        inShopify,
        inBigCommerce
      }

      try {
        sectionValue.bc_element = JSON.parse(bc_element)
        sectionValue.sp_element = JSON.parse(sp_element)
        sectionValue.cms_element = JSON.parse(cms_element)
      } catch (e) {
        console.log('Warn 8', e)
        return this.$store.commit('notify', 'JSON format incorrect')
      }
      this.isSaving = true

      if (this.openingSection.changed.includes('thumbnail')) {
        const uploadTask = this.uploadImage({ fileName: originSectionValue.thumbnailUrl, thumbnailBase64: this.openingSection.thumbnailBase64 })
        tasks.push(uploadTask)
      }

      if (snippet.intersectionArray(this.openingSection.changed, ['name', 'category', 'bc_element', 'sp_element', 'cms_element']).length) {
        const updateSectionTask = this.updateSectionValue({ recordId, sectionValue })
        tasks.push(updateSectionTask)
      }

      await Promise.all(tasks)
      originSectionValue.name = this.openingSection.name
      originSectionValue.key = this.openingSection.key
      originSectionValue.category = this.openingSection.category
      originSectionValue.bc_element = this.openingSection.bc_element
      originSectionValue.sp_element = this.openingSection.sp_element
      originSectionValue.cms_element = this.openingSection.cms_element
      this.isSaving = false
      this.openingSection.changed = []
    },
    handleUploadLocalImage(e) {
      let file = e.target.files[0]
      if (!file.type.match(/image.*/)) return

      let reader = new FileReader()
      reader.addEventListener('load', async () => {
        let base64Data = reader.result
        const blob = await fetch(base64Data).then(res => res.blob())
        const url = window.URL.createObjectURL(blob, { type: file.type })
        this.openingSection.thumbnailUrl = url
        this.openingSection.thumbnailBase64 = base64Data

        let type = 'thumbnail'
        if (!this.openingSection.changed.includes(type)) this.openingSection.changed.push(type)
      }, false)

      if (file) {
        reader.readAsDataURL(file)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.zn-dragable-chose {
  border-color: $secondary !important;
  opacity: 1;
}

.zn-dragable-drag {
  opacity: 0;
}

.zn-main {
  .zn-topbar {
    .zn-topbar-right {
      display: flex;

      .zn-button {
        height: 100%;
      }
    }
  }

  .zn-page-container {
    display: flex;
    height: calc(100% - 60px);
    padding: 8px;
    overflow: hidden;
    border-top: 1px solid $light;

    .zn-page-sidebar {
      flex: 0 0 280px;
      display: flex;
      flex-direction: column;
      padding: 8px;
      background: white;
      overflow: hidden;

      .zn-search {
        padding-right: 8px;
      }

      .zn-section-container {
        overflow-x: hidden;
        overflow-y: scroll;
        margin-right: -8px;

        .zn-section-item {
          padding: 8px 0;
          cursor: pointer;
          white-space: nowrap;
          border: 1px dashed transparent;

          &.zn-is-hidden {
            color: $fade;
          }

          &.zn-section-opening {
            color: $secondary;
          }

          &:first-child {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .zn-main-page {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      padding: 8px;
      padding-right: 40px;
      margin: 0 -8px 0 8px;
      background: white;
      overflow-x: hidden;
      overflow-y: auto;

      .zn-section-part {
        &:not(:first-child) {
          padding-top: 16px;

          input {
            padding: 4px 8px;
            border: 1px solid $fade;
            border-radius: 3px;
            outline: none;
            font-size: inherit;
            min-width: 280px;
            margin-bottom: 16px;
          }
        }

        & > div:first-child {
          margin-bottom: 8px;

          &.changed {
            color: $secondary;
          }
        }
      }

      .zn-section-title {
        input {
          padding: 4px 8px;
          border: 1px solid $fade;
          border-radius: 3px;
          outline: none;
          font-size: inherit;
          min-width: 280px;
        }
      }

      .zn-section-type {
        table {
          tr:not(:last-child) {
            td {
              padding-bottom: 16px;
            }
          }
        }

        .zn-switch {
          margin-left: 16px;
        }
      }

      .zn-section-category {
        input {
          padding: 4px 8px;
          border: 1px solid $fade;
          border-radius: 3px;
          outline: none;
          font-size: inherit;
          min-width: 280px;
        }
      }

      .zn-section-screenshot {
        width: 280px;

        .zn-section-thumbnail {
          padding-bottom: 120%;
          background-position: 0 0;
          background-size: 100%;
          background-repeat: no-repeat;
          cursor: pointer;
          border: 1px solid $light;
          border-radius: 5px;

          &:hover {
            animation-duration: 2s;
            animation-name: scrollBackground;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            animation-timing-function: linear;
          }
        }

        @keyframes scrollBackground {
          0% {
            background-position: 0% 0%;
          }
          10% {
            background-position: 0% 0%;
          }
          90% {
            background-position: 0% 100%;
          }
          100% {
            background-position: 0% 100%;
          }
        }

        .zn-upload-image {
          padding: 0 12px;
          border: 2px dashed $fade;
          border-radius: 5px;
          cursor: pointer;
          margin-left: 16px;
          position: relative;

          input {
            display: none;
          }

          ::v-deep .material-icons-outlined {
            position: absolute;
            top: -1px;
            left: 0;
          }

          &:hover {
            border-color: $primary;
            ::v-deep .material-icons-outlined {
              color: $primary;
            }
          }
        }
      }

      .zn-section-data {
        height: auto;
        min-height: 600px;
        overflow: hidden;
      }
    }
  }
}
</style>
