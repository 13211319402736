import jwtDecode from 'jwt-decode'

export default {
  save(token) {
    localStorage.znToken = token
  },
  get() {
    return localStorage.znToken
  },
  remove() {
    localStorage.removeItem('znToken')
  },
  decode(token) {
    return jwtDecode(token)
  }
}
