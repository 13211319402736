<template>
  <label class="zn-switch">
    <input type="checkbox" :name="name" :checked="value" @change="$emit('input', $event.target.checked)">
    <span class="zn-slider"></span>
  </label>
</template>

<script>
export default {
  props: {
    name: String,
    value: Boolean
  }
}
</script>

<style lang="scss" scoped>
.zn-switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 24px;
  input { 
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .zn-slider {
      background-color: $primary;
      &:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(32px);
      }
    }
  }
  .zn-slider {
    position: absolute;
    cursor: pointer;
    top: 5px;
    left: 0;
    right: 0;
    bottom: 5px;
    border-radius: 5px;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    &:before {
      position: absolute;
      content: "";
      height: 24px;
      width: 24px;
      left: -8px;
      top: -5px;
      border-radius: 50%;
      background-color: white;
      box-shadow: 1px 2px 8px 0px $fade;
      -webkit-transition: .4s;
      transition: .25s;
    }
  }
}
</style>