import request from '@/utils/request.js'
import API_URL from '@/utils/sourceApiUrl'

const state = {
  shopId: '',
  assets: [],
  listAssetEditing: [],
  assetEditing: '',
  loading: false
}

const getters = {
  currentAssetEditing(state) {
    return state.listAssetEditing.find(assetEditing => assetEditing.fileName == state.assetEditing)
  }
}

const mutations = {
  selectShop(state, shopId) {
    state.shopId = shopId
  },
  setAssets(state, assets) {
    state.assets = assets
  },
  addAssetEditing(state, asset) {
    let foundAsset = state.listAssetEditing.find(assetEditing => assetEditing.fileName == asset.fileName)
    if (!foundAsset) state.listAssetEditing.push({ ...asset, changed: false })

    state.assetEditing = asset.fileName
  },
  updateAssetData(state, { fileName, fileData }) {
    const foundAsset = state.listAssetEditing.find(assetEditing => assetEditing.fileName == fileName)
    foundAsset.fileData = fileData
  },
  removeAssetEditing(state, fileName) {
    const indexOfFile = state.listAssetEditing.map(asset => asset.fileName).indexOf(fileName)
    const isDeleteWhenClose = state.listAssetEditing[indexOfFile].isDeleteWhenClose
    state.listAssetEditing.splice(indexOfFile, 1)

    if (isDeleteWhenClose) {
      state.assets = state.assets.filter(asset => asset != fileName)
    }

    if (fileName != state.assetEditing) return

    if (state.listAssetEditing.length) {
      state.assetEditing = indexOfFile - 1 >= 0 ? state.listAssetEditing[indexOfFile - 1].fileName : state.listAssetEditing[indexOfFile].fileName
    } else state.assetEditing = ''
  },
  setActiveAssetEditing(state, fileName) {
    state.assetEditing = fileName
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  setChangedStateCurrentAsset(state, status) {
    let foundAsset = state.listAssetEditing.find(assetEditing => assetEditing.fileName == state.assetEditing)
    foundAsset && (foundAsset.changed = status)
  }
}

const actions = {
  async fetchShopAssets({ state, rootState, commit }) {
    const platform = rootState.platform
    const selectedShop = rootState.shops.listShops[platform].find(shop => shop.id == state.shopId)
    const response = await request.get(`${API_URL[platform]}/admin/shops/${selectedShop.name}/assets`)
    commit('setAssets', response.assets)
    return response.assets
  },
  async activeOrFetchShopAsset({ state, rootState, commit }, templateKey) {
    let foundAsset = state.listAssetEditing.find(assetEditing => assetEditing.fileName == templateKey)
    if (foundAsset) {
      return commit('setActiveAssetEditing', templateKey)
    }

    commit('setLoading', true)
    const platform = rootState.platform
    const selectedShop = rootState.shops.listShops[platform].find(shop => shop.id == state.shopId)
    const assetData = await request.get(`${API_URL[platform]}/admin/shops/${selectedShop.name}/asset`, { templateKey })
    commit('addAssetEditing', assetData)
    commit('setLoading', false)
    return assetData
  },
  async createShopAsset({ state, commit }, { fileName, fileData }) {
    commit('setAssets', [...state.assets, fileName])
    commit('addAssetEditing', { fileName, fileData, isDeleteWhenClose: true })
    commit('setActiveAssetEditing', fileName)
  },
  async updateShopAsset({ state, rootState, commit }, { fileName, fileData }) {
    const platform = rootState.platform
    const selectedShop = rootState.shops.listShops[platform].find(shop => shop.id == state.shopId)
    const response = await request.put(`${API_URL[platform]}/admin/shops/${selectedShop.name}/asset`, { fileName, fileData })
    commit('setChangedStateCurrentAsset', false)
    return response
  },
  async deleteShopAsset({ state, rootState, commit }, templateKey) {
    const platform = rootState.platform
    const selectedShop = rootState.shops.listShops[platform].find(shop => shop.id == state.shopId)
    await request.delete(`${API_URL[platform]}/admin/shops/${selectedShop.name}/asset`, { templateKey })
    commit('removeAssetEditing', templateKey)
    commit('setAssets', state.assets.filter(asset => asset != templateKey))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
