<template>
  <select class="zn-select-platform" @change="setPlatform($event.target.value)" :value="platform" :disabled="disabled">
    <option value="shopify" v-if="ignore != 'shopify'">Shopify</option>
    <option value="bigcommerce" v-if="ignore != 'bigcommerce'">BigCommerce</option>
  </select>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    ignore: String,
    disabled: Boolean
  },
  computed: {
    ...mapState({
      platform: state => state.platform
    })
  },
  methods: {
    ...mapMutations({
      setPlatform: 'setPlatform'
    })
  }
}
</script>

<style lang="scss" scoped>
.zn-select-platform {
  width: 200px;
  padding: 12px;
  font-size: 14px;
  border: 1px solid $light;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.5s;
}
</style>
