<template>
  <div class="zn-select-search">
    <div class="zn-select-search-input">
      <Search
        allowClear
        v-model="keySearchShop"
        :loading="loading"
        :placeholder="placeholder"
        :class="{ 'zn-loading': loading }"
        @focus="showListSelect = true"
      />
    </div>
    <div class="zn-select-search-list" v-if="showListSelect">
      <div class="zn-select-search-mask" @click="showListSelect = false"></div>

      <ul v-if="optionsFilter.length">
        <li v-for="option in optionsFilter" :key="option.value" @click="handleSelectOption(option)">{{ option.label }}</li>
      </ul>

      <div v-else class="zn-select-search-empty">
        <EmptyState title="No shop found" />
      </div>
    </div>
  </div>
</template>

<script>
import Search from './Search.vue'

export default {
  components: {
    Search
  },
  props: {
    placeholder: {
      type: String,
      default: 'Select...'
    },
    options: {
      // { value, label, isDefault }
      type: Array,
      required: true
    },
    loading: Boolean
  },
  data() {
    return {
      keySearchShop: '',
      showListSelect: false
    }
  },
  computed: {
    optionsFilter() {
      let clone = [...this.options]
      clone = clone.filter(option => option.label.indexOf(this.keySearchShop) >= 0)
      return clone
    }
  },
  created() {
    this.setInitialValue()
  },
  methods: {
    handleSelectOption(option) {
      this.$emit('select', option.value)
      this.showListSelect = false
      this.keySearchShop = option.label
    },
    setInitialValue() {
      const optionDefault = this.options.find(option => option.isDefault)
      if (optionDefault) {
        this.keySearchShop = optionDefault.label
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.zn-select-search {
  position: relative;

  .zn-select-search-input {
    height: 100%;

    input {
      font-size: 16px;
      border: none;
      outline: none;
    }
  }

  .zn-select-search-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    min-width: 160px;
    margin-top: 4px;
    z-index: 9999;

    ul {
      max-height: 300px;
      overflow: overlay;
      border: 1px solid $light;
      border-radius: 5px;
      background-color: white;
      padding: 4px 0;

      li {
        cursor: pointer;
        padding: 4px 8px;

        &:hover {
          background-color: rgba($primary, 0.1);
        }
      }
    }

    .zn-select-search-empty {
      background-color: white;
      border: 1px solid $light;
      border-radius: 5px;

      .zn-empty {
        margin-top: 0;
      }

      ::v-deep .zn-empty .zn-empty-image svg {
        width: 100%;
      }
    }

    .zn-select-search-mask {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }
}
</style>
