<template>
  <div class="zn-sidebar">
    <div class="zn-sidebar-wrapper">
      <div class="zn-header">
        <router-link to="/">
          <div class="zn-logo">
            <img src="https://media.zenobuilder.com/asset/logo.png" />
          </div>
        </router-link>
        <div class="zn-brand zn-bold">
          Zeno Admin
        </div>
      </div>

      <div class="zn-account">
        <div class="zn-account-owner zn-bold">
          <span>Hi, {{ userInfo.name }}</span>
          <Icon @click.native="handleLogout" name="logout" />
        </div>
        <div class="zn-account-position zn-light" >
          {{ userInfo.roles[userInfo.roles.length - 1] }}
        </div>
      </div>

      <div class="zn-page-menus">
        <div class="zn-menu-section">
          <div class="zn-menu-separator">
            <div class="zn-label">Tools</div>
          </div>

          <template v-for="nav in slider">
            <router-link v-if="isRender(nav.roles)" :key="nav.key" :to="nav.link">
              <div class="zn-page-menu"><Icon :name="nav.icon" /> {{ nav.name }}</div>
            </router-link>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import slider from '@/config/slider.json'
import { mapState, mapGetters } from 'vuex'
import * as snippet from '@/utils/snippet.js'

export default {
  data() {
    return {
      slider
    }
  },
  methods: {
    isRender(roles) {
      if (!roles) return true

      return snippet.intersectionArray(this.userInfo.roles, roles).length > 0
    },
    async handleLogout() {
      this.$router.push('/login')
      this.$store.dispatch('auth/logout')
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    }),
    ...mapGetters({
      isAdmin: 'auth/isAdmin'
    })
  }
}
</script>

<style lang="scss" scoped>
.zn-sidebar-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 0px 30px rgba(black, 0.1);
  overflow-y: auto;

  ::v-deep a {
    text-decoration: none;
  }

  .zn-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    height: 60px;
    border-right: 1px solid $light;

    ::v-deep a:first-child {
      display: inline-block;
      flex-basis: 24px;
      margin-right: 8px;
      .zn-logo {
        display: flex;
        align-items: center;
        img {
          width: 100%;
        }
      }
    }
    .zn-brand {
      color: $input;
      font-size: 18px;
      flex-grow: 1;
    }
  }

  .zn-account {
    border-top: 1px solid $light;
    border-bottom: 1px solid $light;
    padding: 16px;
    background-color: $primary;

    .zn-account-owner {
      font-size: 18px;
      font-weight: 400;
      color: white;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      i {
        display: inline;
        float: right;
        cursor: pointer;
      }
    }

    .zn-account-position {
      font-size: 15px;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-transform: capitalize;
      color: $warning;
      margin-top: 8px;
      max-width: 240px;
      overflow: hidden;
      cursor: pointer;
    }

    .zn-loading {
      opacity: 0.6;
    }
  }

  .zn-page-menus {
    padding: 8px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .zn-menu-section {
      &:first-child {
        flex-grow: 1;
      }

      ::v-deep a {
        color: black;
        text-decoration: none;

        &.router-link-exact-active {
          color: $primary;

          .zn-pro-effective-days {
            background-color: $primary;
          }
        }
      }

      .zn-page-menu {
        display: flex;
        align-items: center;
        margin: 8px 0;
        padding: 10px 0;
        font-weight: 500;

        ::v-deep i {
          margin-right: 8px;
        }
      }

      .zn-menu-separator {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .zn-label {
          opacity: 0.7;
          text-transform: uppercase;
          font-size: 15px;
        }
      }
    }
  }
}
</style>
