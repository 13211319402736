import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth'
import shops from './shops'
import shopAssets from './shopAssets'
import template from './template'
import section from './section'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    confirm: null,
    alert: null,
    notify: null,
    prompt: null,
    platform: 'shopify',
  },
  mutations: {
    confirm(state, confirm) {
      state.confirm = confirm
    },
    alert(state, alert) {
      state.alert = alert
    },
    notify(state, notify) {
      state.notify = notify
      if (window.znNotifyTimeout) {
        clearTimeout(window.znNotifyTimeout)
      }
      window.znNotifyTimeout = setTimeout(() => {
        state.notify = null
        window.znNotifyTimeout = undefined
      }, 3000)
    },
    prompt(state, prompt) {
      state.prompt = prompt
    },
    setPlatform(state, platform) {
      state.platform = platform
    }
  },
  actions: {
  },
  modules: {
    auth,
    shops,
    shopAssets,
    template,
    section
  }
})
