<template>
  <div class="zn-login">
    <div class="zn-login-wrapper">
      <div class="zn-login-title">
        <img src="https://media.zenobuilder.com/asset/logo.png" />
        <span>Zeno Admin</span>
      </div>

      <div class="zn-google-login" @click.prevent="loginWithGoogle">
        <LoadingSvg class="zn-custom-loading" v-if="loading" />
        <svg v-else class="zn-google-logo" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" aria-hidden="true">
          <title>Google</title>
          <g fill="none" fill-rule="evenodd">
            <path fill="#4285F4" d="M17.64 9.2045c0-.6381-.0573-1.2518-.1636-1.8409H9v3.4814h4.8436c-.2086 1.125-.8427 2.0782-1.7959 2.7164v2.2581h2.9087c1.7018-1.5668 2.6836-3.874 2.6836-6.615z"></path>
            <path fill="#34A853" d="M9 18c2.43 0 4.4673-.806 5.9564-2.1805l-2.9087-2.2581c-.8059.54-1.8368.859-3.0477.859-2.344 0-4.3282-1.5831-5.036-3.7104H.9574v2.3318C2.4382 15.9832 5.4818 18 9 18z"></path>
            <path fill="#FBBC05" d="M3.964 10.71c-.18-.54-.2822-1.1168-.2822-1.71s.1023-1.17.2823-1.71V4.9582H.9573A8.9965 8.9965 0 0 0 0 9c0 1.4523.3477 2.8268.9573 4.0418L3.964 10.71z"></path>
            <path fill="#EA4335" d="M9 3.5795c1.3214 0 2.5077.4541 3.4405 1.346l2.5813-2.5814C13.4632.8918 11.426 0 9 0 5.4818 0 2.4382 2.0168.9573 4.9582L3.964 7.29C4.6718 5.1627 6.6559 3.5795 9 3.5795z"></path>
          </g>
        </svg>
        <span>Continue with Google</span>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router/index'
import LoadingSvg from '@/assets/loading.svg'

export default {
  name: 'GoogleLogin',
  components: {
    LoadingSvg
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async loginWithGoogle() {
      // Reference:  https://developers.google.com/identity/sign-in/web/backend-auth
      try {
        const googleUser = await this.$gAuth.signIn()
        this.loading = true
        await this.$store.dispatch('auth/login', googleUser.getAuthResponse().id_token)
        router.push('/')
      } catch (err) {
        console.log('Warn 2: ', err)
        this.$store.commit('notify', 'Login failed')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.zn-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .zn-login-wrapper {
    display: flex;
    flex-direction: column;
    width: 400px;
    min-height: 250px;
    padding: 32px 40px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba($fade, 0.3);
    margin-bottom: 200px;

    .zn-login-title {
      display: flex;
      justify-content: center;
      margin-bottom: 60px;

      img {
        width: 40px;
        height: 40px;
        margin-right: 12px;
      }
      span {
        font-size: 30px;
      }
    }

    .zn-google-login {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px;
      box-sizing: border-box;
      border-radius: 5px;
      box-shadow: 0px 0px 10px rgba($fade, 0.3);
      cursor: pointer;

      .zn-google-logo {
        margin-right: 12px;
      }

      .zn-custom-loading {
        margin: unset !important;
        background: unset !important;
        width: 28px;

        rect {
          fill: $primary;
        }
      }
    }
  }
}
</style>
