<template>
  <main class="zn-main" :class="{ 'zn-processing': loading }">
    <div class="zn-topbar">
      <div class="zn-topbar-left">
        <Platform class="zn-topbar-action" ignore="bigcommerce" :class="{ 'zn-loading': loading }" :disabled="loading" />
        <SelectSearch
          class="zn-topbar-action zn-select-shop"
          placeholder="Search shop..."
          :class="{ 'zn-loading': loading }"
          :options="listShops.map(shop => ({ value: shop.id, label: 'Shop ' + shop.id, isDefault: shop.id == shopId }))"
          :loading="loading"
          @select="selectShop(+$event)"
        />
      </div>

      <div class="zn-topbar-right" v-if="assetEditing != ''">
        <Button type="danger" @click="handleDeleteFile" :disabled="isDeleting" :loading="isDeleting" class="mr-2">{{ isDeleting ? 'Deleting' : 'Delete' }}</Button>
        <Button type="primary" @click="handleSaveFile" :disabled="!isChangedCurrentAsset() || isSaving" :loading="isSaving">{{ isSaving ? 'Saving' : 'Save' }}</Button>
      </div>
    </div>
    <div class="zn-page-container">
      <ShopAssetSidebar />

      <div class="zn-main-page">
        <div class="zn-content">
          <div class="zn-content-container">
            <ShopAssetEditor @save="handleSaveFile" />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import auth from '@/middleware/auth'
import author from '@/middleware/author'
import Shop from '@/components/Shop.vue'
import Pagination from '@/components/Pagination'
import ShopAssetSidebar from '@/components/shopAsset/ShopAssetSidebar.vue'
import ShopAssetEditor from '@/components/shopAsset/ShopAssetEditor.vue'
import SelectSearch from '@/components/common/SelectSearch.vue'
import PAGINATION_SIZE from '@/config/paginationSize.js'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import Platform from '@/components/Platform.vue'

export default {
  middleware: [auth, author(['admin', 'dev'])],
  components: {
    Shop,
    Pagination,
    ShopAssetSidebar,
    ShopAssetEditor,
    SelectSearch,
    Platform
  },
  data() {
    return {
      query: '',
      pagination: 1,
      PAGINATION_SIZE,
      isSaving: false,
      isDeleting: false
    }
  },
  computed: {
    ...mapState({
      sourceShops: state => state.shops.listShops,
      platform: state => state.platform,
      shopId: state => state.shopAssets.shopId,
      loading: state => state.shopAssets.loading,
      listAssetEditing: state => state.shopAssets.listAssetEditing,
      assetEditing: state => state.shopAssets.assetEditing
    }),
    ...mapGetters({
      fetchedListShops: 'shops/fetchedListShops',
      currentAssetEditing: 'shopAssets/currentAssetEditing'
    }),
    listShops() {
      let clone = this.sourceShops[this.platform]
      clone = clone.filter(shop => JSON.stringify(shop).includes(this.query))
      clone = clone.sort((a, b) => a.id - b.id)
      return clone
    }
  },
  watch: {
    fetchedListShops: {
      handler(val) {
        if (!val && this.platform == 'shopify') {
          this.fetchData()
        }
      },
      immediate: true
    },
    shopId: {
      async handler(val) {
        if (val && this.platform == 'shopify') {
          this.setLoading(true)
          await this.fetchShopAssets()
          this.setLoading(false)
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions({
      fetchListShop: 'shops/fetchListShop',
      fetchShopAssets: 'shopAssets/fetchShopAssets',
      updateShopAsset: 'shopAssets/updateShopAsset',
      deleteShopAsset: 'shopAssets/deleteShopAsset'
    }),
    ...mapMutations({
      selectShop: 'shopAssets/selectShop',
      setLoading: 'shopAssets/setLoading'
    }),
    async fetchData() {
      this.setLoading(true)
      try {
        await this.fetchListShop()
      } catch (err) {
        console.log('Warn 6:',err)
        this.$store.commit('notify', 'Cannot get list shop, see more in devtool')
      }
      this.setLoading(false)
    },
    async handleSaveFile() {
      this.isSaving = true
      try {
        await this.updateShopAsset(this.currentAssetEditing)
      } catch (err) {
        console.log('Warn 4:',err)
        this.$store.commit('notify', 'Cannot save file, please see more in devtool')
      }
      this.isSaving = false
    },
    async handleDeleteFile() {
      this.$store.commit('confirm', {
        title: `Are you sure delete file?`,
        message: `File <b>${this.currentAssetEditing.fileName}</b> will be delete and cannot undo.`,
        status: 'danger',
        confirmText: 'Delete',
        confirmHandler: async () => {
          this.isDeleting = true
          try {
            await this.deleteShopAsset(this.currentAssetEditing.fileName)
          } catch (err) {
            this.$store.commit('notify', 'Cannot delete file, please see more in devtool')
            console.log('Warn 5:',err)
          }
          this.isDeleting = false
        }
      })
    },
    isChangedCurrentAsset() {
      const assetEditing = this.listAssetEditing.find(asset => asset.fileName == this.assetEditing)
      return assetEditing && assetEditing.changed
    }
  }
}
</script>

<style lang="scss" scoped>
.zn-main {
  .zn-topbar {
    .zn-topbar-left {
      .zn-topbar-action:not(:first-child) {
        margin-left: 8px;
      }

      .zn-select-shop {
        display: inline-block;
        width: 200px;
        font-size: 14px;
        border-radius: 5px;
        outline: none;
        transition: border-color 0.5s;
      }
    }

    .zn-topbar-right {
      display: flex;

      .zn-button {
        height: 100%;
      }
    }
  }

  .zn-page-container {
    display: flex;
    width: 100%;
    height: calc(100% - 60px);
    padding: 8px;
  
    .zn-main-page {
      flex: 1 1 auto;
      position: relative;
      margin-left: 8px;

      .zn-content-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }
}
</style>
