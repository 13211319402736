<template>
  <div class="zn-empty">
    <div class="zn-empty-image">
      <EmptySvg />
    </div>
    <div class="zn-empty-title">
      {{ title }}
    </div>
    <div class="zn-empty-description">
      {{ description }}
    </div>
    <div class="zn-empty-action">
      <slot> </slot>
    </div>
  </div>
</template>

<script>
import EmptySvg from '@/assets/empty.svg'

export default {
  components: {
    EmptySvg
  },
  props: {
    title: String,
    description: String
  }
};
</script>

<style lang="scss" scoped>
.zn-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;

  .zn-empty-image {
    margin-bottom: 32px;
    svg {
      width: 360px;
    }
  }
  .zn-empty-title {
    font-size: 18px;
  }
  .zn-empty-description {
    font-size: 16px;
    color: $fade;
    margin: 8px 0 24px 0;
  }
  .zn-empty-action {
    button {
      font-size: 16px;
      font-weight: 500;
      background-color: $primary;
      border: 1px solid $fade;
      border-radius: 5px;
      color: white;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 8px 16px;
      outline: none;

      &:hover {
        opacity: 0.8;
      }
      ::v-deep .material-icons-outlined {
        margin-right: 4px;
      }
    }
  }
}
</style>
