import request from '@/utils/request.js'
import API_URL from '@/utils/sourceApiUrl'

const FAKE_DATA = [
  {
    key: 'zeno-fake1',
    name: 'Zeno Fake',
    thumbnailUrl: '',
    previewUrl: '',
    bc_elements: '',
    sp_elements: '',
    cms_elements: ''
  },
  {
    key: 'zeno-fake2',
    name: 'Zeno Fake',
    thumbnailUrl: '',
    previewUrl: '',
    bc_elements: '',
    sp_elements: '',
    cms_elements: ''
  },
  {
    key: 'zeno-fake3',
    name: 'Zeno Fake',
    thumbnailUrl: '',
    previewUrl: '',
    bc_elements: '',
    sp_elements: '',
    cms_elements: ''
  }
]

const state = {
  templates: FAKE_DATA
}

const getters = {
  fetchedTemplate(state, getters, rootState) {
    return state.templates != FAKE_DATA
  }
}

const mutations = {
  setListTemplate(state, templates) {
    state.templates = templates
  }
}

const actions = {
  async fetchListTemplate({ state, rootState, commit }) {
    const response = await request.get(`${API_URL.bigcommerce}/admin/templates`)
    commit('setListTemplate', response.templates)
    return response.templates
  },
  async getContentPreview({ state, rootState, commit }, template) {
    if (template.previewContent) return

    const response = await request.get(`${API_URL.bigcommerce}/admin/proxy`, { url: template.previewUrl })
    const templateInState = state.templates.find(item => item.recordId == template.recordId)
    templateInState.previewContent = response.html
    return response.html
  },
  async getTemplateData({ state, rootState, commit }, template) {
    if (template.bc_elements && template.sp_elements && template.cms_elements) return

    const response = await request.get(`${API_URL.bigcommerce}/admin/templates/${template.recordId}`)
    const templateInState = state.templates.find(item => item.recordId == template.recordId)
    templateInState.bc_elements = JSON.stringify(response.template.bc_elements, null, 2)
    templateInState.sp_elements = JSON.stringify(response.template.sp_elements, null, 2)
    templateInState.cms_elements = JSON.stringify(response.template.cms_elements, null, 2)
    return 1
  },
  async uploadImage({}, { fileName, thumbnailBase64 }) {
    const response = await request.upload(`${API_URL.bigcommerce}/admin/templates/thumbnail?fileName=${fileName}`, thumbnailBase64)
    return response
  },
  async updatePreviewContent({}, payload) {
    const response = await request.post(`${API_URL.bigcommerce}/admin/templates/preview`, payload)
    return response
  },
  async updateTemplateValue({}, { recordId, templateValue }) {
    const response = await request.put(`${API_URL.bigcommerce}/admin/templates/${recordId}`, templateValue)
    return response
  },
  async reOrderListTemplate({}, body) {
    const response = await request.put(`${API_URL.bigcommerce}/admin/templates/re-order`, body)
    return response
  },
  async setHiddenTemplate({}, { id, value }) {
    const response = await request.delete(`${API_URL.bigcommerce}/admin/templates/${id}?value=${value}`)
    return response
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
